import {
  INFORMATION_LEGAL_SET,
  INFORMATION_IMPRESSUM_SET,
  INFORMATION_B2B_SET,
  INFORMATION_PRICELIST_SET,
  INFORMATION_BONUSCARD_SET,
  INFORMATION_FAMILYFILMCLUB_SET,
  INFORMATION_BIRTHDAYROOM_SET,
  INFORMATION_COMPETITIONS_SET,
  SLIDER_CONTENT_SUCCESS,
  SUCCESS_QUESTION_FORM_DATA,
  REQUEST_COMPETITION_DETAIL_SUCCESS,
  COMPETITION_EVENTS_SUCCESS,
  INFORMATION_FAQ_SUCCESS,
  LANDING_INFO_SUCCESS,
  HALLTICKET_BOOK_SUCCESS,
  INFORMATION_PRICELIST_GASTRONOMY_SET,
  FAQS_REGISTRATION_SUCCESS,
  FOOTER_NEWSLETTER_REGISTRATION_SUCCESS,
  GET_FFCSIGNUP_NOTE_SUCCESS,
  GET_MAIN_URL_SUCCESS
} from "./types";

const initialState = {
  legalInfoContent: [],
  impressumContent: null,
  b2bContent: null,
  priceListContent: null,
  bonusCardInfo: null,
  familyFilmClub: null,
  birthdayRoom: null,
  locations: null,
  competitions: [],
  slider: [],
  competitions_detail: [],
  competitionEvents: [],
  faq: null,
  landing: [],
  hallTicket: [],
  priceListContentGastronomy: [],
  faqsRegistration: [],
  newsLetterResult: {},
  ffCNote: [],
  urlData: []
};

export default function(state = initialState, { type, payload }) {
  switch (type) {
    case INFORMATION_LEGAL_SET: {
      return {
        ...state,
        legalInfoContent: payload.legalInfoContent
      };
    }

    case INFORMATION_IMPRESSUM_SET: {
      return {
        ...state,
        impressumContent: payload.impressumContent
      };
    }

    case INFORMATION_B2B_SET: {
      return {
        ...state,
        b2bContent: payload.b2bContent
      };
    }

    case INFORMATION_PRICELIST_SET: {
      return {
        ...state,
        priceListContent: payload?.priceListContent
      };
    }

    case INFORMATION_BONUSCARD_SET: {
      return {
        ...state,
        bonusCardInfo: payload.bonusCardInfo
      };
    }

    case INFORMATION_FAMILYFILMCLUB_SET: {
      return {
        ...state,
        familyFilmClub: payload.familyFilmClub
      };
    }

    case INFORMATION_BIRTHDAYROOM_SET: {
      return {
        ...state,
        birthdayRoom: payload.birthdayRoom
      };
    }

    case INFORMATION_COMPETITIONS_SET: {
      return {
        ...state,
        competitions: payload.competitions?.data,
        slider: []
      };
    }

    case SLIDER_CONTENT_SUCCESS: {
      const details = { ...payload?.slider };

      let list = state?.slider;

      // ! getting competetion id from url
      const url = window.location.href.split("/");
      const urlCompetetionId = url[url.length - 1];

      // ! getting competetion from redux state
      const competetion = state?.competitions.find(
        item => item.id === urlCompetetionId
      );

      // ! getting unique slider array
      const uniqueSliders = list?.map(e => e.id);
      // ! getting film codes array
      const filmCodes = competetion?.HOFilmCodes?.map(e => e.HOFilmCode);
      // ! Checking for flag

      let flag = false;

      filmCodes?.length &&
        filmCodes.forEach(code => {
          if (!uniqueSliders.includes(code)) {
            flag = true;
          }
        });
      // ! if flag and condition true then add
      if (
        flag &&
        (filmCodes.includes(details?.id) ||
          filmCodes.includes(details?.HOFilmCode))
      ) {
        list.push({
          id: details?.id,
          title: details?.titleCalculated,
          image: details?.gallery[0]
        });
      }

      const key = "id";

      const arrayUniqueByKey = [
        ...new Map(list.map(item => [item[key], item])).values()
      ];

      list = arrayUniqueByKey;

      return {
        ...state,
        slider: list
      };
    }

    case SUCCESS_QUESTION_FORM_DATA: {
      return {
        ...state,
        data: payload?.data
      };
    }

    case REQUEST_COMPETITION_DETAIL_SUCCESS: {
      return {
        ...state,
        competitions_detail: payload?.data
      };
    }

    case COMPETITION_EVENTS_SUCCESS: {
      return {
        ...state,
        competitionEvents: payload.competitionEvents?.data[0]?.events
      };
    }

    case INFORMATION_FAQ_SUCCESS: {
      return {
        ...state,
        faq: payload.FaqInfo
      };
    }

    case LANDING_INFO_SUCCESS: {
      return {
        ...state,
        landing: payload.LandingInfo?.data
      };
    }

    case HALLTICKET_BOOK_SUCCESS: {
      return {
        ...state,
        hallTicket: payload.hallTicket?.data
      };
    }

    case INFORMATION_PRICELIST_GASTRONOMY_SET: {
      return {
        ...state,
        priceListContentGastronomy: payload?.priceListContentGastronomy
      };
    }

    case FAQS_REGISTRATION_SUCCESS: {
      return {
        ...state,
        faqsRegistration: payload.faqsRegistration
      };
    }

    case FOOTER_NEWSLETTER_REGISTRATION_SUCCESS: {
      return {
        ...state,
        newsLetterResult: payload.newsLetterRegistration
      };
    }

    case GET_FFCSIGNUP_NOTE_SUCCESS: {
      return {
        ...state,
        ffCNote: payload?.ffcNote?.data
      };
    }

    case GET_MAIN_URL_SUCCESS: {
      return {
        ...state,
        mainUrl: payload?.urlData?.data
      };
    }

    default: {
      return state;
    }
  }
}
