import styled from 'styled-components';
import { ShadowBoxDark } from 'app/components';
import { textTransform } from 'utils';

export const SectionEntities = styled.section.attrs({
  className: 's-entities'
})`
  flex: 1 0 auto;
  padding: 0 0 48px;

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.md.width}) {
    padding: ${({ noPadding }) => (noPadding ? '0' : '46px')} 0 48px;
    .fixed-event-all-detail{
      position: fixed;
      width: 100%;
      background-color: black;
      left: 0;
      z-index: 5
    }
    .event-list{
      margin-top:106px;
    }
    
    .fixed-movie-date-location{
    // position: fixed;
    position: sticky;
    width: 100%;
    background-color: black;
    left: 0;
    z-index: 5;
    padding:0 12px;
    top: 92px;
    }
    
    .movie-list{
        // padding-top:120px; 
      //  padding-top:92px;
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakPoints.sm.width}) {
    padding: ${({ noPadding }) => (noPadding ? '0' : '47px')} 0 48px;
    .event-list{
      margin-top:90px;
    }
    
    .movie-list{
       /* padding-top:100px; */
      //  padding-top:76px;
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.xs.width}) {
    padding: ${({ noPadding }) => (noPadding ? '0' : '46px')} 0 48px;
  }
  @media screen and (max-width: 360px) {
    padding: ${({ noPadding }) => (noPadding ? '0' : '30px')} 0 48px;
  }
  @media screen and (max-width: 320px) and (min-width: 280px) {
    /* .fixed-movie-date-location {
      margin-top: 14px;    
    } */
    .fixed-movie-date-location{
      // top: 140px
      // top:92px;
      top:75px;
    }
    padding: ${({ noPadding }) => (noPadding ? '0' : '32px')} 0 48px;
  }
`;

export const SectionEntityDetails = styled.section.attrs({
  className: 's-entity-details'
})`
  flex: 1 0 auto;
  padding: ${({ theme }) => theme.indent.m} 0 48px;

  .s-entity-details__nav-tabs {
    display: none;
    width: 100%;
    margin-bottom: ${({ theme }) => theme.indent.m};
    font-size: 0;
  }

  & > .container {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.md.width}) {
    padding: 0 0 48px;
    overflow: hidden;

    .s-entity-details__nav-tabs {
      display: block;
    }
  }
`;

export const MainEntityDetails = styled.main.attrs({
  className: 's-entity-details__main'
})`
  width: calc(100% - 329px);
  padding-right: ${({ theme }) => theme.indent.xl};

  .b-no-content + .b-entity-content,
  .b-entity-gallery + .b-entity-content,
  .b-entity-gallery + .b-no-content,
  .b-entity-gallery + .b-cinema-info,
  .b-entity-gallery + .l-sessions,
  .b-title-with-poster + .b-entity-content,
  .b-title-with-poster + .b-no-content,
  .b-entity-gallery + .b-shadows-box-dark,
  .b-entity-gallery + .b-session-state,
  .b-session-state + .l-sessions,
  .b-session-state + .b-no-content {
    margin-top: ${({ theme }) => theme.indent.l};
  }
  .b-entity-content + .b-cinema-info {
    margin-top: ${({ theme }) => theme.indent.s};
  }

  .b-entity-content .l-entity {
    margin-top: ${({ theme }) => theme.indent.m};
  }
  .b-session-state + .b-entity-content {
    margin-top: ${({ theme }) => theme.indent.xl};
  }
  @media screen and (max-width: 375px){
    .b-session-state + .b-entity-content {
      // margin-top:${({ gallery }) => (gallery ? `55px` : ``)};
    }
  }
  @media screen and (max-width: 320px){
    .b-session-state + .b-entity-content {
      margin-top:${({ gallery }) => (gallery ? `25px` : ``)};
    }
  }
  @media screen and (min-width: 376px)and  (max-width: 430px){
    .b-session-state + .b-entity-content {
      // margin-top:${({ gallery }) => (gallery ? `70px` : ``)};
    }
  }
  @media screen and (min-width: 431px)and  (max-width: 540px){
    .b-session-state + .b-entity-content {
      margin-top:${({ gallery }) => (gallery ? `110px` : ``)};
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakPoints.md.width}) {
    display: ${({ isVisible }) => (isVisible ? `block` : `none`)};
    width: 100%;
    padding-right: 0;
    margin-top: 16px;

    .cinema-nogallery-details{
      margin-top: 80px;
    }
    .b-no-content + .b-entity-content {
      margin-top: ${({ theme }) => theme.indent.m};
    }
    .cinema-details__date{
      /* margin-top: 15px; */
      margin:15px 0px;
    }
    .fixed-cinema-detail{
    position: fixed;
    width: 100%;
    background-color: black;
    left: 0;
    margin-top: -20px;
    z-index: 5;
  }
  .cinema-list-details {
    /* margin-top: 285px;  */
    margin-top: 65px;
    }
  }
  @media screen and (max-width: 377px) {
    .cinema-list-details {
      margin-top: 70px;     
    }
    /* .fixed_movie_detail + .b-session-state{
      margin-top:50px;
    } */
  }
  .fixed_movie_detail{
    & .carousel {
      margin: 0;
    }
    & .b-carousel__wrapper{
      margin-bottom: 16px;
    }
     
   /* .b-entity-content + .b-cinema-info {
    margin-top: 50px;
  } */
  }
  @media screen and (max-width: ${({ theme }) => theme.breakPoints.md.width})   {
    
    .fixed_movie_detail{
      ${({ type }) => type == 'no-session' ? `position: relative` : `position: fixed`}
      width: 100%;
      background-color: ${({ theme }) => theme.color.bgPrimary};
      z-index: 5;
      left: 0;
      margin-top: -20px;
    }

    .fixed_movie_detail + .b-session-state{
      // margin-top: 292px;
      margin-top: ${({ gallery }) => (gallery ? `370px` : `292px`)};
      /* margin-top: 150px; */
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakPoints.sm.width}) {
    .fixed_movie_detail + .b-session-state{
      margin-top: ${({ gallery }) => (gallery ? `350px` : `230px`)};;
      // margin-top: 230px;
      /* margin-top: 130px; */
      /* margin-top:30px; */
      } 
      }
      @media screen and (max-width: 320px) {
        .fixed_movie_detail + .b-session-state{
          margin-top: ${({ gallery }) => (gallery ? `300px` : `220px`)};;
      // margin-top: 300px;
      }
    .cinema-nogallery-details{
      margin-top: 104px;
    }
  }
`;

export const AsideEntityDetails = styled(ShadowBoxDark).attrs({
  className: 's-entity-details__aside'
})`
  width: 329px;
  padding: 0px 0px 20px 0px;
  // overflow: hidden;

  .s-entity-details__aside-inner {
    padding: ${({ theme }) => `12px ${theme.indent.m} ${theme.indent.s}`};
  }

  .s-entity-details__aside-inner > p{
    letter-spacing: 0.8px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.md.width}) {
    display: ${({ isVisible }) => (isVisible ? `block` : `none`)};
    width: 100%;
  }
`;
export const VideoPosterEntityDetails = styled(ShadowBoxDark).attrs({
  className: 's-entity-details__aside'
})`
  width: 329px;
  /* padding: 0px 0px 20px 0px; */
  // overflow: hidden;

  .s-entity-details__aside-inner {
    padding: ${({ theme }) => `12px ${theme.indent.m} ${theme.indent.s}`};
  }

  .s-entity-details__aside-inner > p{
    letter-spacing: 0.8px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.md.width}) {
    display:block
    width: 100%;
  }
`;

export const EntityInfo = styled.div.attrs({
  className: 'b-entity-info'
})`
  .b-entity-info__row {
    width: 100%;
    float: left;
    &.b-entity-info__age {
      position: relative;
    }

    & > strong {
      display: inline-block;
      margin-right: ${({ theme }) => theme.indent.s};
      font-size: 1.6rem;
      font-weight: 500;
      line-height: 1.5;
      color: ${({ theme }) => theme.color.labelColor};

      &._block {
        display: block;
        margin-right: 0;
      }
    }

    span {
      display: block;
      font-size: 14px;
    }
    .phone-number{
      text-decoration:underline;
      color:#de1619;
      cursor:pointer
    }
    svg.svg-car {
      width: 20px;
      height: 20px;
      vertical-align: bottom;
    }
    .display-inline {
      display: flex;
    justify-content: flex-start;
    align-items: center;
    }

    .display-inline span p {
      margin-left: 10px;
    }
    /* .display-inline ul li {
      margin-right: 10px;
    } */
  }

  .b-entity-info__age-text {
    width: 100%;
    max-width: 295px;
    padding: ${({ theme }) => theme.indent.m};
    border-radius: 8px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.61), 0 0 4px 0 rgba(0, 0, 0, 0.6);
    background-color: ${({ theme }) => theme.color.bgSecondary};
    position: absolute;
    top: calc(100% + 3px);
    left: 50%;
    z-index: 1;
    transform: translateX(-50%) scale(0.5);
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.18s ease, transform 0.2s ease-out;
  }

  .b-entity-info__age-mark {
    display: inline-block;
    vertical-align: sub;
    margin-left: ${({ theme }) => theme.indent.s};
    width: 18px;
    height: 18px;

    &:hover {
      .svg-question-mark {
        opacity: 1;
      }

      & + .b-entity-info__age-text {
        opacity: 1;
        transform: translateX(-50%) scale(1);
        span.age_title {
          display: inline !important;
        }
      }
    }

    .svg-question-mark {
      width: 100%;
      height: 100%;
      fill: ${({ theme }) => theme.color.textPrimary};
      opacity: 0.8;
      transition: opacity 0.2s ease-out;
    }
  }

  .b-entity-info__row + .b-entity-info__row {
    margin-top: 15px;
  }

  .s-cinema-details__link-prices span.text-upper {
    text-transform: ${textTransform};
    // color: #888888;
    color:#DE1619;
    display: initial;
    vertical-align: bottom;
    text-decoration: underline;
  }
  .go_map {
    margin-bottom: 15px;
  }


`;

export const EntityContent = styled(ShadowBoxDark).attrs({
  className: 'b-entity-content'
})`
  padding: ${({ theme }) => `${theme.indent.m} ${theme.indent.m} 18px`};

  & ~ .b-entity-content {
    margin-top: ${({ theme }) => theme.indent.l};
  }
    
   ${({ isCinemaSession, isgalleryData, isTrailerData }) =>
    isCinemaSession &&
    `
      @media screen and (max-width: 320px) {
         margin-top: ${isgalleryData ? '240px' : isTrailerData ? '156px' : '140px'};
      }
      @media screen and (min-width:321px) and (max-width:375px){
        margin-top: ${isgalleryData ? '260px' : isTrailerData ? '156px' : '140px'};
      }
      @media screen and (min-width:376px) and (max-width:568px){ 
        margin-top: ${isgalleryData ? '280px' : isTrailerData ? '156px' : '156px'};
      }
      @media screen and (min-width:440px) and (max-width:576px){
        margin-top: ${isgalleryData ? '310px' : isTrailerData ? '156px' : '156px'};
      }
      @media screen and (min-width:577px) and (max-width:640px){
        margin-top: ${isgalleryData ? '380px' : isTrailerData ? '190px' : '200px'};
      }
      @media screen and (min-width:641px) and (max-width:740px){
        margin-top: ${isgalleryData ? '410px' : isTrailerData ? '190px' : '200px'};
      }
      @media screen and (min-width:741px) and (max-width:992px){
        margin-top: ${isgalleryData ? '280px' : isTrailerData ? '190px' : '200px'};
      }
    `}

  .b-entity-content__link-wrapper {
    position: relative;
    padding-left: 30px;

    & > button {
      position: absolute;
      left: 0;
    }
  }

  .b-entity-content__text {
    margin-top: ${({ theme }) => theme.indent.s};
  }

  .b-entity-content__text > p {
    letter-spacing: 0.8px;
  }

  .b-entity-content__text > ol > li {
    display: list-item;
    list-style: auto;
  }

  .b-entity-content__text > ul {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }

  .b-entity-content__text > ul > li {
    display: list-item;
    list-style: disc;
  }

  .b-entity-content__title {
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 1.44;
    letter-spacing: 1.5px;
    text-transform: ${textTransform};
    color: ${({ theme }) => theme.color.accent};
  }

  .b-entity-content__link {
    transition: color 0.2s ease-out;
    backface-visibility: hidden;

    &:hover {
      color: ${({ theme }) => theme.color.textPrimary};
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.md.width}) {
    & ~ .b-entity-content {
      margin-top: ${({ theme }) => theme.indent.l};
    }
  }
  .screen-popup{
    position: fixed;
    background-color: rgba(0, 0, 0, 0.6);
    width:100%;
    height: 100%;
    left: 0%;
    top: 0%;
    z-index: 20;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: default;
  }

  .close-btn{
    float: right;
  }

  .screen-popup img{
    min-width: 300px;
  }

  .screen-img img{
    width:100%;
    height: 226px;
  }
  #entity-content a{    
      text-decoration: underline;
      color: #DE1619;  
  }
`;

export const Width50 = styled.div`
  width: 50%;
  float: left;
  strong {
    display: block;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.5;
    color: #888;
    // margin-bottom: 10px;
  }
  svg {
    width: 50px;
    height: 50px;
    // margin-bottom: 10px;
  }
`;
