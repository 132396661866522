import styled from 'styled-components';
import { ShadowBoxDark } from 'app/components';
import { textTransform } from 'utils';

export default styled(ShadowBoxDark).attrs({
  className: 'b-no-content'
})`
  width: 100%;
  ${({ type, theme }) =>
    type === 'account' ? `padding: 175px ${theme.indent.m};` : `padding: 100px ${theme.indent.m};`}
  text-align: center;

  .svg-no-movies,
  .svg-no-cinema,
  .svg-no-tickets,
  .svg-no-cinema,
  .svg-no-tickets {
    width: 64px;
    height: 64px;
    fill: ${({ theme }) => theme.color.rgbaWhite('.15')};
  }

  .b-no-content__text {
    margin-top: 12px;
    color: ${({ theme }) => theme.color.labelColor};
    font-size: 1.7rem;
    ${({ type }) => type === 'account' && `text-transform: ${textTransform}`}
  }
  @media screen and (max-width: ${({ theme }) => theme.breakPoints.md.width}) {
    /* padding: 190px 16px 120px; */
  }
  @media screen and (max-width: ${({ theme }) => theme.breakPoints.sm.width}) {
    padding: 75px ${({ theme }) => theme.indent.m};

    .svg-no-movies,
    .svg-no-cinema,
    .svg-no-tickets,
    .svg-no-cinema,
    .svg-no-tickets {
      width: 50px;
      height: 50px;
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.xs.width}) {
    padding: 50px ${({ theme }) => theme.indent.s}; // fixed the top bar 

    // ${({ type }) => type === 'no-session' && `margin-top: 158px;`}
    /* padding: 120px 8px 30px */
    .b-no-content__text {
      margin-top: ${({ theme }) => theme.indent.s};
    }
  }
  @media screen and (min-width:768px)  and (max-width:992px){
    // ${({ type }) => type === 'no-session' && `margin-top: 198px;`}
  }
`;
