import styled from 'styled-components';
import { withChevron } from 'app/theme';
import { textTransform } from 'utils';

export const MainSection = styled.div.attrs({
  className: 'main-box-section'
})`
padding: 14px 12px;
border: 1px solid #343434;
border-radius: 8px;
@media screen and (max-width: 768px) { 
  // margin-top:16px;  //commented for nachos mobile view
  margin:16px;
  }
`;
export const SubSection = styled.div.attrs({
  className: 'sub-section'
})`
display: flex;
align-items: center;
justify-content: space-between;
`;
export const SubSection1 = styled.div.attrs({
  className: 'sub-section1'
})`
display: flex;
// align-items: center;
align-items: start;  // for cold drink modal only
gap: 15px;

.image-section{
    border: 1px solid #343434;
    border-radius: 8px;
    padding: 10px;
    background-color: #2C2C2C;
     .rewardImage{
    height:96px;
    width:70px;
  }
}
.image-section-mobile-coldDrink{  
  border: 1px solid #343434;
  border-radius: 8px;  
  background-color: #2C2C2C;  
  padding:8px 21px; // for cold drink modal only
  img{
    height:96px;
    width:70px;
  }
}
.image-section-mobile-Nachos{  
  border: 1px solid #343434;
  border-radius: 8px;  
  background-color: #2C2C2C;  
  padding:8px 8px; // for nachos modal only
  img{ 
    height:96px;
    width:96px; //for nachos in mobile view
  }
}

.content-section{
  display: flex;
  align-items:start;
  flex-direction: column;
  // gap: 26px;
  gap: 14px;
}
.content-heading{
  font-size:16px;
  font-weight:500;
  line-height:20px;
  // padding-bottom:16px;
  text-transform:uppercase;
}
.content-subHeading{
  display:flex;
  align-items:start;
  flex-direction:column;
  gap:8px;
  font-size:14px;
  font-weight:500;
  color:rgb(255 255 255/ 80%);
  text-transform:uppercase;
}

@media screen and (max-width: 768px) {
  // for Cold Drink
  .content-heading{
    font-size:14px;
    font-weight:600;
  }
}
@media screen and (max-width: 320px) {
  // for Cold Drink
  .content-subHeading{
    position: relative;
    top: 10px;
    font-size:10px;
  }
}
`;
export const SubSection2 = styled.div.attrs({
  className: 'sub-section2'
})`

display: flex;
flex-direction: column;
justify-content: space-between;
padding: 10px 16px;
.boxMenu_inputField{
  width: 100%;
  height: 38px;
  background-color:#323232;
  border-radius: 8px;
  // margin: 10px 0px;
  text-align:center;
  padding:8px;
  border: 1px solid #3B3B3B;
}
.total-section{
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: space-between;
  // height: 24vh;
  gap:26px;
}
.total-sub-section{
  display: flex;
  flex-direction: column;
  align-items: end;
}
.sub-section1{
  font-size: 14px;
    font-weight: 500;
    color: rgb(255 255 255 / 80%);
    line-height: 24px;
}
.sub-section2{
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
}
.total-section-mobile-view{
  display: flex;
  justify-content: space-between;
  align-items: start;
}
@media screen and (max-width: 768px) { 
  .sub-section1{
    font-size: 12px;
    font-weight: 500;
  }
  .boxMenu_inputField{
    width: 116px;
  }
}
`;
export const TotalSubSectionMobileViewColdDrink = styled.div.attrs({
  className: 'total-sub-section'
})`
display: flex;
flex-direction:column;
align-Items:end;
line-height:20px;
font-size:12px;
font-weight:500;
color:#FFFFFFCC;
`

export const SelectionSelection = styled.div.attrs({
  className: 'Selection'
})`
margin-top:20px;

.total-sub-section{
  display: flex;
  align-items: end;
  flex-direction: column;
  padding-top: 32px; //commented for Cold drink in mobile view
  color: #FFFFFFCC;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
}
.price-in-mobile-view{
  text-align:right;
  font-size:20px;
  font-weight:700;
  line-height:30px;
}
@media screen and (max-width: 768px) { 
  // margin:20px 16px;
  margin:16px;
.total-sub-section{
  font-size: 12px;
  line-height: 20px;
  padding-top: 0px; //commented for Cold drink in mobile view
}
}
`;
export const SelectionHeadingSelection = styled.div.attrs({
  className: 'heading-Selection'
})`
border: 1px solid #343434;
    border-radius: 8px 8px 0px 0px;
    padding: 8px 12px;
    // margin-top: 24px; //commented for nachos in mobile view sections 
    background-color: #2C2C2C;
    .header-section{
      display: flex;
    align-items: center;
    gap: 16px;
    }
  .number-section{
    border-radius: 50%;
    background-color: #DE1619;
    padding: 2px 9px;
  }
  .number-sub-section{
    font-size: 16px;
    text-align: center;
  }
  .heading-content-section{
    display: flex;
    align-items: self-start;
    flex-direction: column;
  }
  .heading-content-subSection1{
    color: #DE1619;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }
  .heading-content-subSection2{
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: rgb(255 255 255 / 80%);
  }

  @media screen and (max-width: 768px){
    .heading-content-subSection1{
      font-size: 14px;
      line-height: 20px;
    }
  }
`;
export const SelectionItemSelection = styled.div.attrs({
  className: 'item-Selection'
})`
border: 1px solid #343434;
border-radius: 0px 0px 8px 8px;
padding: 4px 0px;
display: flex;
flex-direction: column;
gap: 4px;
.box-menu-radio{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  // color:#fff;
  color:#FFFFFFCC;
  border-top: 1px solid #343434;
  padding: 8px 16px;
}
.coldDrink-menu-radio{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  // color:#fff;
  color:#FFFFFFCC;
  border-top: 1px solid #343434;
}
`;
export const SelectionItemColdDrinkSelection = styled.div.attrs({
  className: 'coldDrink-section'
})`
border: 1px solid #343434;
border-radius: 0px 0px 8px 8px;
display: flex;
flex-direction: column;
.colddrink-menu-radio{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  // color:#fff;
  color:#FFFFFFCC;
  border-top: 1px solid #343434;
  // padding: 8px 16px;
}
`;

export const ButtonSection = styled.div.attrs({
  className: 'btn-section'
})`
.main-div{
  padding: 40px 32px 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
}
.clear-btn{
  border: 2px solid rgb(255 255 255 / 49%);
  padding: 11px 30px;
  width: 168px;
  height: 40px;
  background-color: #191919;
  border-radius: 30px;
  line-height: 18px;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 450;
  cursor:pointer;
}
.add-btn{
  padding: 11px 30px;
  width: 200px;
  height: 40px;
  background-color: #DE1619;
  border-radius: 30px;
  line-height: 18px;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 450;
  cursor:pointer;
}
`;

export const SelectionMobileBoxMenu = styled.div.attrs({
  className: 'b-selection-total-mob'
})`
  display: none;
  width: 100%
  min-width: 320px;
  position: fixed;
  z-index: 12;
  left: 0;
  bottom: 0;
  border-radius: 8px 8px 0 0;
  box-shadow: 0 -2px 4px 0 ${({ theme }) => theme.color.rgbaBlack('.2')};

  .b-selection-total-mob__mask {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 9;
    top: 0;
    left: 0;
    background-color: ${({ theme }) => theme.color.rgbaBlack('.5')};
    transition: opacity .2s ease-out;
    opacity: 0;
    pointer-events: none;
  }
  
  &._expanded .b-selection-total-mob__mask {
    opacity: 1;
    pointer-events: auto;
  }

  .b-selection-total-mob__info-wrapper {
    display: flex;
    justify-content: space-between;
    // padding: 5px 16px;
    position: relative;
    z-index: 9;
    border-radius: 8px 8px 0 0;
    background-color:#242424;
  }

  .b-selection-total-mob__info-inner {
    display: inline-block;
    vertical-align: middle;
  }

  .b-selection-total-mob__info {
     ${withChevron}
     
    ._with-chevron {
      vertical-align: middle;
    }
  }

  &._expanded .b-selection-total-mob__info ._with-chevron::after {
    transform: rotate(315deg) translate(-2px,6px);
  }

  .b-selection-total-mob__info-inner + .b-selection-total-mob__info-inner {
    margin-left: ${({ theme }) => theme.indent.m};
  }

  .b-selection-total-mob__info-title {
    font-size: 1.2rem;
    font-weight: 500;
    text-transform: ${textTransform};
    color: ${({ theme }) => theme.color.labelColor};
  }

  .b-selection-total-mob__info-value {
    font-size: 1.8rem;
    font-weight: 500;
    color: #fff;
  }

  .b-selection-total-mob__description {
    max-height: 0;
    padding: 0 ${({ theme }) => theme.indent.m};
    border-top: 1px solid ${({ theme }) => theme.color.rgbaWhite('.2')};
    background-color: ${({ theme }) => theme.color.bgSecondary};
    position: relative;
    z-index: 9;
    transition: max-height .2s ease, padding .15s ease;
    backface-visibility: hidden;
    overflow: hidden;
  }
  
  &._expanded .b-selection-total-mob__description {
    max-height: 135px;
    padding: ${({ theme }) => `${theme.indent.s} ${theme.indent.m} ${theme.indent.m}`};
  }

  .b-selection-total-mob__description-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .total-section-box-menu{
    border:1px solid #3B3B3B;
    border-top-left-radius:8px;
    border-top-right-radius:8px;
    margin-top:16px;
  }
  .b-selection-total-mob__description-inner + .b-selection-total-mob__description-inner {
    margin-top: 5px;
  }
  
  .b-selection-total-mob__description-text {
    font-size: 1.8rem;
    color: #fff;
  }
  
  .b-selection-total-mob__description-text:last-child {
    flex-shrink: 0;
    margin-left: ${({ theme }) => theme.indent.m};
  }
  @media screen and (max-width: ${({ theme }) => theme.breakPoints.md.width}) {
    display: block;
    overflow:scroll;
    height:80vh;
  }
`;