import React from 'react';
import { format } from 'date-fns';
// import { useTranslation } from 'react-i18next';

export const isClassBlock = text => (text && text.length > 40 ? '_block' : null);

export const printInfo = (data, text, isPhoneNumber = false) => {
  // const [t] = useTranslation();

  return data && data.length ? (
    <div className="b-entity-info__row">
      <strong className={isClassBlock(text + data)}>{text}</strong>
      {isPhoneNumber ? <span className='phone-number' onClick={() => document.location.href = `tel:+${data}`}>{Array.isArray(data) ? data.join(', ') : data}</span> :
        <span>{Array.isArray(data) ? data.join(', ') : data}</span>
      }
    </div>
  ) : null;
};

export const getEventDates = (startDate, endDate, getDateWithOffset) => {
  if (!startDate || !endDate) return undefined;

  const formatDate = date => format(getDateWithOffset(date), 'dd.MM.yyyy');

  return `${formatDate(startDate)}${startDate !== endDate ? ` — ${formatDate(endDate)}` : ''}`;
};
