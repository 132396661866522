import { connect } from 'react-redux';
import PickupModal from './PickupModal.component';
import concessionsModule from 'app/store/concessions';

const { pickUpNumberRequest, pickUpNumberSuccess } = concessionsModule.actions;

const mapStateToProps = () => ({

});
const mapDispatchToProps = {
  pickUpNumber: pickUpNumberRequest,
  setPickUpNumber: pickUpNumberSuccess
};
export default connect(mapStateToProps, mapDispatchToProps)(PickupModal);
