import BaseAPI from "../BaseAPI";

/**
 * Api class to get rewards
 */

class Bonuscard extends BaseAPI {
  /**
   * Creates Rewards instance
   *
   * @param props {Object}
   * @return {Rewards}
   */
  constructor(props) {
    super(props);

    this.baseUrl = "/users";
  }

  /**
   * Gets rewards list
   * @return {Promise<*|Error>}
   */
  getRewards() {
    const url = `${this.baseUrl}/rewards`;

    return this.get({ url });
  }

  lightMemberRegister(body) {
    let type = "";
    if (body?.type === "unregister") {
      type = `delete`;
    } else {
      type = `register`;
    }
    const url = `${this.baseUrl}/lightmember/${type}`;
    delete body.type;
    return this.post({ url, body });
  }

  /**
   * Gets user balance
   * @return {Promise<*|Error>}
   */
  getBalance() {
    const url = `${this.baseUrl}/balance`;

    return this.get({ url });
  }

  /**
   * Gets bonus card balance concessions
   * @return {Promise<*|Error>}
   */
  getBalanceConcessions() {
    const url = `${this.baseUrl}/balance/concessions`;

    return this.get({ url });
  }

  /**
   * Saves order concessions
   * @return {Promise<*|Error>}
   */
  orderConcession(concessionId) {
    const url = `${this.baseUrl}/balance/concessions`;
    const version = "/api/v1";
    const body = {
      concessionId
    };

    return this.post({ url, body, version });
  }

  activateXxtraCard() {
    const url = `${this.baseUrl}/activate-xxcard`;

    return this.get({ url });
  }

  loggedUser() {
    const url = `${this.baseUrl}`;
    return this.get({ url });
  }
}

export default Bonuscard;
