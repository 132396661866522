import styled from 'styled-components';
import { withChevron } from 'app/theme';
import { textTransform } from 'utils';

export const MainSectionMobileView = styled.div.attrs({
  className: 'main-menu'
})`
.sub-menu{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  border-bottom: 1px solid #FFFFFF14;
}
.title{
  font-size: 18px;
  font-weight: 500;
}
.cross-img{
  height: 14px;
  width: 14px;
}
`;
export const Selection = styled.div.attrs({
  className: 'b-selection-total-mob'
})`
  display: none;
  width: 100%
  min-width: 320px;
  position: fixed;
  z-index: 12;
  left: 0;
  bottom: 0;
  border-radius: 8px 8px 0 0;
  box-shadow: 0 -2px 4px 0 ${({ theme }) => theme.color.rgbaBlack('.2')};

  .b-selection-total-mob__mask {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 9;
    top: 0;
    left: 0;
    background-color: ${({ theme }) => theme.color.rgbaBlack('.5')};
    transition: opacity .2s ease-out;
    opacity: 0;
    pointer-events: none;
  }
  
  &._expanded .b-selection-total-mob__mask {
    opacity: 1;
    pointer-events: auto;
  }

  .b-selection-total-mob__info-wrapper {
    display: flex;
    justify-content: space-between;
    // padding: 5px 16px;   //commented for nachos mobile view
    position: relative;
    z-index: 9;
    border-radius: 8px 8px 0 0;
    // background-color: ${({ theme }) => theme.color.bgSecondary};
    background-color: #242424;
  }

  .b-selection-total-mob__info-inner {
    display: inline-block;
    vertical-align: middle;
  }

  .b-selection-total-mob__info {
     ${withChevron}
     
    ._with-chevron {
      vertical-align: middle;
    }
  }

  &._expanded .b-selection-total-mob__info ._with-chevron::after {
    transform: rotate(315deg) translate(-2px,6px);
  }

  .b-selection-total-mob__info-inner + .b-selection-total-mob__info-inner {
    margin-left: ${({ theme }) => theme.indent.m};
  }

  .b-selection-total-mob__info-title {
    font-size: 16px;
    font-weight: 500;
    // text-transform: ${textTransform};
    color: rgb(255 255 255 / 80%);
  }

  .b-selection-total-mob__info-value {
    font-size: 1.8rem;
    font-weight: 500;
    color: #fff;
  }

  .b-selection-total-mob__description {
    max-height: 0;
    padding: 0 ${({ theme }) => theme.indent.m};
    // border-top: 1px solid ${({ theme }) => theme.color.rgbaWhite('.2')};
    background-color: ${({ theme }) => theme.color.bgSecondary};
    position: relative;
    z-index: 9;
    transition: max-height .2s ease, padding .15s ease;
    backface-visibility: hidden;
    // overflow: hidden;
    overflow: scroll;
  }
  
  &._expanded .b-selection-total-mob__description {
    max-height: 310px;
    // padding: ${({ theme }) => `${theme.indent.s} ${theme.indent.m} ${theme.indent.m}`};
    padding: 0px 10px 0px;
  }

  .b-selection-total-mob__description-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .b-selection-total-mob__description-inner + .b-selection-total-mob__description-inner {
    margin-top: 5px;
  }
  
  .b-selection-total-mob__description-text {
    font-size: 1.8rem;
    color: #fff;
  }
  
  .b-selection-total-mob__description-text:last-child {
    flex-shrink: 0;
    margin-left: ${({ theme }) => theme.indent.m};
  }
  

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.md.width}) {
    display: block;
  }
`;