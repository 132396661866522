import styled from 'styled-components';
import { LineBordered as BorderedLine } from 'app/components';
import { textTransform } from 'utils';

export const LineBordered = styled(BorderedLine)`
  font-size: 1.8rem;
  line-height: 1.33;
  border-top: 0;

  h3 {
    font-weight: 390;
    font-size: 18px;
    line-height: 24px;

    &.invalid {
      color: #de1619;
    }
  }
`;

export const Seats = styled(BorderedLine)`
  justify-content: start;
`;
export const SeatsAfterSelections = styled.div`
@media screen and (max-width: 999px) {
  display:none;
}
`;

export const SeatsItem = styled.span`
  margin-right: ${({ theme }) => theme.indent.m};
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.5;
  text-transform: ${textTransform};
`;

export const Total = styled.span`
  text-transform: ${textTransform};
  font-weight: 700;
  font-size: 1.6rem;

  .svg-cart {
    vertical-align: middle;
    width: 24px;
    height: 24px;
    margin-right: ${({ theme }) => theme.indent.s};
  }

  & > span {
    vertical-align: middle;
  }
`;
