/* eslint-disable react-hooks/exhaustive-deps  */
/* eslint-disable array-callback-return  */
/* eslint-disable jsx-a11y/anchor-is-valid  */
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import useTheme from 'app/hooks/useTheme';
import useModal from 'app/hooks/useModal';
import { ModalCarousel, Carousel, ImageWithLoader } from 'app/components';
import { CarouselItem, Gallery } from './styles';

const propTypes = {
  gallery: PropTypes.arrayOf(PropTypes.string).isRequired
};

const carouselPropsConfig = tablet => ({
  slidesToSlide: 2,
  responsive: {
    tablet: {
      breakpoint: {
        max: 30000,
        min: tablet
      },
      items: 6
    },
    mobile: {
      breakpoint: {
        max: tablet,
        min: 0
      },
      items: 2
    }
  }
});

const sliderPropsConfig = () => ({
  slidesToSlide: 1,
  responsive: {
    desktop: {
      breakpoint: {
        max: 30000,
        min: 0
      },
      items: 1
    }
  }
});

const EntityGallery = ({ gallery }) => {
  const theme = useTheme();
  const [modalCarousel, showModalCarousel, hideModalCarousel] = useModal('carousel');
  const [index, setIndex] = useState(0);
  const carouselRef = useRef(null);
  const sliderRef = useRef(null);
  const carouselProps = useMemo(
    () => carouselPropsConfig(parseInt(theme.breakPoints.sm.width, 10)),
    [theme]
  );
  const sliderProps = useMemo(() => sliderPropsConfig(), []);

  const onModalClose = useCallback(() => {
    hideModalCarousel();
    Carousel.fireResize();
  }, []);

  /**
   * Manage Carousel
   */
  useEffect(() => {
    if (carouselRef.current) {
      const { totalItems, slidesToShow } = carouselRef.current.state;
      const idx = index < totalItems - slidesToShow ? index : totalItems - slidesToShow;

      if (totalItems - slidesToShow > 0) {
        carouselRef.current.goToSlide(idx);
      }
    }
  }, [index, modalCarousel]);

  return (
    <Gallery>
      <Carousel {...carouselProps} refEl={carouselRef}>
        {gallery && gallery?.map((imgSrc, i) => (
          <CarouselItem
            key={imgSrc}
            onClick={() => {
              setIndex(i);
              showModalCarousel();
            }}
          >
            <ImageWithLoader imgSrc={imgSrc} />
          </CarouselItem>
        ))}
      </Carousel>

      <ModalCarousel isOpen={modalCarousel} close={onModalClose}>
        <Carousel
          {...sliderProps}
          refEl={sliderRef}
          afterChange={(previousSlide, { currentSlide }) => {
            setIndex(currentSlide);
          }}
          initialSlideNumber={index}
        >
          {gallery.map(imgSrc => (
            <div key={imgSrc}>
              <ImageWithLoader imgSrc={imgSrc} />
            </div>
          ))}
        </Carousel>
      </ModalCarousel>
    </Gallery>
  );
};

EntityGallery.propTypes = propTypes;
export default EntityGallery;
