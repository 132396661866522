export const CONCESSIONS_REQUEST_CATEGORY = 'cinemas/CONCESSIONS_REQUEST_CATEGORY';
export const CONCESSIONS_SUCCESS_CATEGORY = 'cinemas/CONCESSIONS_SUCCESS_CATEGORY';

export const CONCESSIONS_REQUEST_ITEM = 'cinemas/CONCESSIONS_REQUEST_ITEM';
export const CONCESSIONS_SUCCESS_ITEM = 'cinemas/CONCESSIONS_SUCCESS_ITEM';

export const CONCESSIONS_REQUEST_COMBO = 'cinemas/CONCESSIONS_REQUEST_COMBO';
export const CONCESSIONS_SUCCESS_COMBO = 'cinemas/CONCESSIONS_SUCCESS_COMBO';

export const CONCESSIONS_REQUEST_COMBO_ITEM = 'cinemas/CONCESSIONS_REQUEST_COMBO_ITEM';
export const CONCESSIONS_SUCCESS_COMBO_ITEM = 'cinemas/CONCESSIONS_SUCCESS_COMBO_ITEM';

export const CONCESSIONS_ON_SERVER = 'cinemas/CONCESSIONS_ON_SERVER';
export const CONCESSIONS_SUCCESS_ON_SERVER = 'cinemas/CONCESSIONS_SUCCESS_ON_SERVER';

export const CONCESSIONS_COMBO_ON_SERVER = 'cinemas/CONCESSIONS_COMBO_ON_SERVER';
export const CONCESSIONS_SUCCESS_COMBO_ON_SERVER = 'cinemas/CONCESSIONS_SUCCESS_COMBO_ON_SERVER';

export const CONCESSIONS_DATA_STORE = 'cinemas/CONCESSIONS_DATA_STORE';

export const CONCESSIONS_REMOVE = 'cinemas/CONCESSIONS_REMOVE';

export const PICKUP_NUMBER_REQUEST = 'bookings/PICKUP_NUMBER_REQUEST';
export const PICKUP_NUMBER_SUCCESS = 'bookings/PICKUP_NUMBER_SUCCESS';