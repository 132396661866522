import styled from 'styled-components';

const Container = styled.div.attrs({
  className: 'container'
})`
  width: 100%;
  max-width: 1238px;
  margin: 0 auto;
  padding: 0 16px;

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.sm.width}) {
    padding: 0 10px;
    .nachos_title{
      display:none;
    }
  }
`;

export default Container;
