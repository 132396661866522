import styled from 'styled-components';
import { LineBordered, ShadowBoxDark } from 'app/components';

export const SectionPurchase = styled.section.attrs({
  className: 's-purchase'
})`
  margin-bottom: auto;
`;

export const RowButton = styled.div.attrs({
  className: 's-purchase__btn-next'
})`
  margin-top: ${({ theme }) => theme.indent.l};
  text-align: right;

  button {
    min-width: 200px;

    & + button {
      margin: 0 0 0 ${({ theme }) => theme.indent.l};
    }
  }
 
@media screen and (min-width: 426px) and (max-width: 990px) {
  .pay_btn_position{
    position: relative;
    top: -15;
  }
}
  button.disabled {
    box-shadow: none;
    pointer-events: none;
    background-color: rgba(255,255,255,.05);
    color: rgba(255,255,255,.2);
  } 

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.md.width}) {
    clear: left;
    text-align: center;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.xs.width}) {
    button {
      width: 100%;
    }

    button + button {
      margin: ${({ theme }) => theme.indent.m} 0 0 0;
    }
  }
`;


export const PurchaseButtonSection = styled.div.attrs({
  className: 's-purchase__section'
})`
 display: flex;
 margin-top:20px;

 span{
  padding-right:20px;
 }
 
 @media screen and (max-width: ${({ theme }) => theme.breakPoints.md.width}) {
    flex-direction: column;
    align-items: center;
  }
`;


export const StepWrapper = styled(ShadowBoxDark).attrs({
  className: 'b-step-wrapper'
})`
  padding: ${({ theme }) => theme.indent.l};
  margin-bottom: ${({ theme }) => theme.indent.m};
  width: 100%;
  float: left;

  span.giftcard_note {
    color: #A1A1A1;
    font-style: normal;
    font-weight: 390;
    font-size: 16px;
    line-height: 24px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.sm.width}) {
    margin-bottom: 0;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.sm.width}) {
    padding: ${({ theme }) => `${theme.indent.m} ${theme.indent.s}`};
  }
`;

export const Page = styled.div`
  margin-bottom: ${({ theme }) => theme.indent.m};

  .b-ticket {
    ${LineBordered} + ${LineBordered} {
      border-top: 0;
    }
  }

  &._seatplan {
    display: flex;
    flex-wrap: wrap;
  }

  .b-seatplan {
    width: calc(100% - 329px);
    padding-right: ${({ theme }) => theme.indent.l};
  }

  .b-ticket {
    ${LineBordered} {
      & > span {
        font-weight: 500;
      }
    }
  }

  .b-modal-checkout-payment {
    text-align: right;

    .btn {
      min-width: auto;
    }
  }


  @media screen and (max-width: ${({ theme }) => theme.breakPoints.md.width}) {
    .b-seatplan {
      width: 100%;
      padding-right: 0;
    }
  }
`;

export const Info = styled.div`
  margin-top: ${({ theme }) => theme.indent.s};
  color: ${({ theme, color }) => color || theme.color.textSecondary};
`;

export const WarningInfo = styled.section`
  margin-top: ${({ theme }) => theme.indent.m};

  @media screen and (min-width: ${({ theme }) => theme.breakPoints.md.width}) {
    margin-left: calc(355px + ${({ theme }) => theme.indent.l});
  }
`;

export const WarningInfoTitle = styled.h4`
  font-weight: 400;

  ._accent {
    color: ${({ theme }) => theme.color.accent};
  }
`;

export const WarningInfoDescription = styled.p`
  color: ${({ theme }) => theme.color.textSecondary};
`;
