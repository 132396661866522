import { Button, Checkbox, Container, InputNumber, Label, LineBordered, Modal, Radio, Svg } from 'app/components';
import { SectionEntities } from 'app/components/Entity';
import { useModal } from 'app/hooks';
import React, { useEffect, useState } from 'react';
import { MainSection, SelectionHeadingSelection, SelectionItemSelection, SelectionSelection, SubSection, SubSection1, SubSection2, ButtonSection, SelectionItemColdDrinkSelection, TotalSubSectionMobileViewColdDrink } from '../BoxMenu/styles';
import Nachos from '../../../assets/images/NachosModal.png';
import ColdDrink from '../../../assets/images/coldDrink.png';
import { Total } from 'app/modules/order/SelectionTotal/styles';
import { useTranslation } from 'react-i18next';
import CrossIcon from '../../../assets/images/close.png';
import { RowButton } from '../styles';
import { MainSectionMobileView, Selection } from './styles';

// For Nachos
const menuDetail = [
  {
    header: 'Select Extra Flavour',
    options: [
      'Kase 130 g',
      'Salsa 130 g',
    ]
  },
]



// For ColdDrink
// const menuDetail = [
//   {
//     header: 'Select Size and Quantity', // for Cold Drinks
//     options: [{
//       label: 'Coca-Cola 0.5 l',
//       price: '£ 6.40'
//     },
//     {
//       label: 'Coca-Cola 0.75 l',
//       price: '£ 8.40'
//     },
//     {
//       label: 'Coca-Cola 1 l',
//       price: '£ 12.40'
//     }]
//   },
// ]

const ConcessionModal = () => {
  const [t] = useTranslation();
  const [BoxMenu, showBoxMenu, hideBoxMenu] = useModal('BoxMenu');
  const [isExpanded, toggleExpand] = useState(false);
  const [summeDetails, setSummeDetails] = useState(false);

  const handleSummeDetail = () => {
    toggleExpand(!isExpanded);
    setSummeDetails(!summeDetails);
  }
  //  for Cold Drink
  const [selectedItems, setSelectedItems] = useState({});
  const [windowSize, setWindowSize] = useState();

  const updateItemQuantity = (label, quantity) => {
    setSelectedItems({
      ...selectedItems,
      [label]: quantity,
    });
  };
  const calculateTotalPrice = () => {
    let totalPrice = 0;
    for (const label in selectedItems) {
      const quantity = selectedItems[label];
      const selectedItem = menuDetail[0].options.find((item) => item.label === label);
      if (selectedItem) {
        totalPrice += parseFloat(selectedItem.price.replace('£', '')) * quantity;
      }
    }
    return totalPrice.toFixed(2);
  };
  const renderSelectedItems = () => {
    return Object.entries(selectedItems).map(([label, quantity]) => {
      const selectedItem = menuDetail[0].options.find((item) => item.label === label);
      if (selectedItem && quantity > 0) {
        return (
          <React.Fragment key={label}>
            <span className="sub-section">{`${selectedItem.label} : ${quantity} X ${selectedItem.price}`}</span>
            {/* <br /> */}
          </React.Fragment>
        );
      }
      return null;
    });
  };

  const openModal = () => {
    if (windowSize >= 992) {
      showBoxMenu();
    }
    else {
      handleSummeDetail();
    }
  }

  // For Nachos
  const [selectedQuantities, setSelectedQuantities] = useState({});
  const handleQuantityChange = (flavor, value) => {
    setSelectedQuantities(prevQuantities => ({
      ...prevQuantities,
      [flavor]: value
    }));
  };
  useEffect(() => {
    setWindowSize(window.innerWidth);
  }, [window]);

  window.addEventListener('resize', function () {
    setWindowSize(window.innerWidth);
  }, true);

  const nachosPrice = 12.10;

  const totalNachosPrice = Object.values(selectedQuantities).reduce((acc, quantity) => {
    return acc + quantity * nachosPrice;
  }, 0);

  return (
    <SectionEntities>
      <Container>
        <h1>Snacks and Drinks</h1>s
        <Button onClick={() => openModal()}>Open ConcessionModal</Button>
        {/* For Cold Drink in Desktop and Mobile View */}
        {/* {
          windowSize >= 992 ?
            (
              <Modal
                isOpen={BoxMenu}
                close={hideBoxMenu}
                title={"Select items"}
                isBoxMenu={true}
              >
                <MainSection>
                  <SubSection>
                    <SubSection1>
                      <div className='image-section'>
                        <span><img src={ColdDrink} alt='ColdDrink' /></span>
                      </div>
                      <div className='content-section'>
                        <div className='content-heading'>Coca-Cola Drink</div>
                      </div>
                    </SubSection1>
                  </SubSection>
                </MainSection>
                <SelectionSelection>
                  {menuDetail?.map((item, index) => (
                    <>
                      <SelectionHeadingSelection key={index}>
                        <div className='header-section'>
                          <span className='heading-content-section'>
                            <p className='heading-content-subSection1'>{item.header}</p>
                          </span>
                        </div>
                      </SelectionHeadingSelection>
                      <SelectionItemSelection>
                        {item?.options?.map((items, ind) => (
                          < Label className="box-menu-radio" style={{ borderTop: ind === 0 && ('0px') }}>
                            <div>
                              <p>{items?.label}</p>
                              <p>( {items?.price} )</p>
                            </div>
                            <SubSection2>
                              <div className='boxMenu_inputField'>
                                <InputNumber
                                  value={selectedItems[items.label] || 0}
                                  onChange={(val) => updateItemQuantity(items.label, val)}
                                />
                              </div>
                            </SubSection2>
                          </Label>
                        ))}
                      </SelectionItemSelection >
                    </>
                  ))
                  }
                  <div className="total-sub-section">{renderSelectedItems()}</div>

                  <LineBordered style={{
                    marginTop: '14px',
                  }}>
                    <Total>
                      <Svg id="cart" />
                      <span>{t('purchase.total')}:</span>
                    </Total>
                    <strong>£ {calculateTotalPrice()}</strong>
                  </LineBordered>
                </SelectionSelection>
                <ButtonSection>
                  <div className='main-div'>
                    <div className='clear-btn' onClick={() => setSelectedItems({})}>Clear</div>
                    <div className='add-btn' onClick={() => (hideBoxMenu(), setSelectedItems({}))}>Add</div>
                  </div>
                </ButtonSection>
              </Modal>
            )
            :
            (
              <>
                {
                  summeDetails &&
                  <Selection className={isExpanded ? '_expanded' : ''}>
                    <div
                      className="b-selection-total-mob__mask"
                      aria-hidden
                    />
                    <div
                      className="b-selection-total-mob__info-wrapper"
                      aria-hidden
                      style={{
                        flexDirection: 'column'
                      }}
                    >
                      <MainSectionMobileView>
                        <div className='sub-menu'>
                          <p className='title'>Select Item</p>
                          <span className='cross-img'><img src={CrossIcon} alt="crossIcon" onClick={() => handleSummeDetail()} /></span>
                        </div>
                      </MainSectionMobileView>
                      <MainSection>
                        <SubSection>
                          <SubSection1>
                            <div className='image-section-mobile-coldDrink'>
                              <span><img src={ColdDrink} alt='ColdDrink' /></span>
                            </div>
                            <div className='content-section'>
                              <div className='content-heading'>Coca-Cola Drink</div>
                            </div>
                          </SubSection1>
                        </SubSection>
                      </MainSection>
                      <SelectionSelection>
                        {menuDetail?.map((item, index) => (
                          <>
                            <SelectionHeadingSelection key={index}>
                              <div className='header-section'>
                                <span className='heading-content-section'>
                                  <p className='heading-content-subSection1'>{item.header}</p>
                                </span>
                              </div>
                            </SelectionHeadingSelection>
                            <SelectionItemSelection>
                              {item?.options?.map((items, ind) => (
                                < Label className="coldDrink-menu-radio" style={{ borderTop: ind === 0 && ('0px') }}>
                                  <div>
                                    <p>{items?.label}</p>
                                    <p>( {items?.price} )</p>
                                  </div>
                                  <SubSection2>
                                    <div className='boxMenu_inputField'>
                                      <InputNumber
                                        value={selectedItems[items.label] || 0}
                                        onChange={(val) => updateItemQuantity(items.label, val)}
                                      />
                                    </div>
                                  </SubSection2>
                                </Label>
                              ))}
                            </SelectionItemSelection >
                          </>
                        ))
                        }
                      </SelectionSelection>
                      <div style={{
                        // display: "flex",
                        // alignItems: "end",
                        // flexDirection: "column",
                        border: '1px solid #3B3B3B',
                        borderTopLeftRadius: "8px",
                        borderRopRightRadius: "8px",
                        // padding: '16px',
                        marginTop: '16px'
                      }}>
                        <SelectionSelection>
                          <div className="total-sub-section">{renderSelectedItems()}</div>
                          {Object.keys(selectedItems)?.length > 0 && <div className="price-in-mobile-view"><strong>£ {calculateTotalPrice()}</strong></div>}
                        </SelectionSelection>
                        <RowButton style={{
                          padding: '16px 20px'
                        }}>
                          <Button isPrimary
                          //  onClick={next}
                          >
                            Add
                          </Button>
                        </RowButton>
                      </div>
                    </div>
                  </Selection>
                }
              </>
            )
        } */}

        {/* For Nachos in Desktop and Mobile View */}
        {
          windowSize >= 992 ?
            (

              < div className='nachos_title'>
                <Modal
                  isOpen={BoxMenu}
                  close={hideBoxMenu}
                  title={"Select items"}
                  isBoxMenu={true}
                >
                  <MainSection>
                    <SubSection>
                      <SubSection1>
                        <div className='image-section'>
                          <span><img src={Nachos} alt='Nachos' /></span>
                        </div>
                        <div className='content-section'>
                          <div className='content-heading'>Nachos medium</div>
                          <div className='content-subHeading'>
                            <div>Kase 130 g</div>
                            <div>Salsa 130 g</div>
                          </div>
                        </div>
                      </SubSection1>
                      <SubSection2>
                        <div>
                          <div className='total-section'>
                            <div className='boxMenu_inputField'>
                              <InputNumber
                                // value={0}
                                // onChange={val => updateItemQuantity(item.description, val)}
                                value={selectedQuantities['Nachos medium'] || 0} onChange={(val) => handleQuantityChange('Nachos medium', val)}
                              />
                            </div>
                          </div>
                        </div>
                      </SubSection2>
                    </SubSection>
                  </MainSection>
                  <SelectionSelection>
                    {menuDetail?.map((item, index) => (
                      <>
                        <SelectionHeadingSelection key={index}>
                          <div className='header-section'>
                            <span className='heading-content-section'>
                              <p className='heading-content-subSection1'>{item.header}</p>
                            </span>
                          </div>
                        </SelectionHeadingSelection>
                        <SelectionItemColdDrinkSelection>
                          {item?.options?.map((items, ind) => (
                            < Label className="colddrink-menu-radio" style={{ borderTop: ind === 0 && ('0px') }}>
                              {items}
                              <SubSection2>
                                <div className='boxMenu_inputField'>
                                  <InputNumber
                                    value={selectedQuantities[items] || 0} onChange={(val) => handleQuantityChange(items, val)}
                                  />
                                </div>
                              </SubSection2>
                            </Label>
                          ))}
                        </SelectionItemColdDrinkSelection >
                      </>
                    ))
                    }
                    <div className='total-sub-section'>
                      {Object.entries(selectedQuantities).map(([flavor, quantity]) => (
                        quantity > 0 && <span className='sub-section1' key={flavor}>{flavor}: {quantity} X £{nachosPrice.toFixed(2)}</span>
                      ))}
                    </div>
                    <LineBordered style={{
                      marginTop: '14px',
                    }}>
                      <Total>
                        <Svg id="cart" />
                        <span>{t('purchase.total')}:</span>
                      </Total>
                      <strong>£ {totalNachosPrice.toFixed(2)}</strong>
                    </LineBordered>
                  </SelectionSelection>
                  <ButtonSection>
                    <div className='main-div'>
                      <div className='clear-btn' onClick={() => setSelectedQuantities({})}>Clear</div>
                      <div className='add-btn' onClick={() => (setSelectedQuantities({}), hideBoxMenu())}>Add</div>
                    </div>
                  </ButtonSection>
                </Modal>
              </div>
            )
            :
            (
              <>
                {
                  summeDetails &&
                  <Selection className={isExpanded ? '_expanded' : ''}>
                    <div
                      className="b-selection-total-mob__mask"
                      aria-hidden
                    />
                    <div
                      className="b-selection-total-mob__info-wrapper"
                      aria-hidden
                      style={{
                        flexDirection: 'column'
                      }}
                    >
                      <MainSectionMobileView>
                        <div className='sub-menu'>
                          <p className='title'>Select Item</p>
                          <span className='cross-img'><img src={CrossIcon} alt="crossIcon" onClick={() => (handleSummeDetail(), setSelectedQuantities({}), hideBoxMenu())} /></span>
                        </div>
                      </MainSectionMobileView>
                      <MainSection>
                        <SubSection>
                          <SubSection1>
                            <div className='image-section-mobile-Nachos'>
                              <span><img src={Nachos} alt='Nachos' /></span>
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                gap: "20px",
                                height: '114px'
                              }}
                              className='content-section'>
                              <div className='content-heading'>Nachos medium</div>
                              <div className='content-subHeading'>
                                <div>Kase 130 g</div>
                                <div>Salsa 130 g</div>
                              </div>
                            </div>
                          </SubSection1>
                        </SubSection>
                      </MainSection>
                      <SelectionSelection>
                        {menuDetail?.map((item, index) => (
                          <>
                            <SelectionHeadingSelection key={index}>
                              <div className='header-section'>
                                <span className='heading-content-section'>
                                  <p className='heading-content-subSection1'>{item.header}</p>
                                </span>
                              </div>
                            </SelectionHeadingSelection>
                            <SelectionItemColdDrinkSelection>
                              {item?.options?.map((items, ind) => (
                                < Label className="colddrink-menu-radio" style={{ borderTop: ind === 0 && ('0px') }}>
                                  {items}
                                  <SubSection2>
                                    <div className='boxMenu_inputField'>
                                      <InputNumber
                                        value={selectedQuantities[items] || 0} onChange={(val) => handleQuantityChange(items, val)}
                                      />
                                    </div>
                                  </SubSection2>
                                </Label>
                              ))}
                            </SelectionItemColdDrinkSelection >
                          </>
                        ))
                        }
                      </SelectionSelection>
                      <div
                        style={{
                          border: '1px solid #3B3B3B',
                          padding: '16px 20px',
                          borderTopLeftRadius: "8px",
                          borderTopRightRadius: "8px",
                          marginTop: '150px'
                        }}
                      >
                        <div style={{
                          display: 'flex',
                          alignItems: 'start',
                          justifyContent: 'space-between',
                          // marginTop: "12px",
                        }}>
                          <SubSection2>
                            <div className='total-section'>
                              <div className='boxMenu_inputField'>
                                <InputNumber
                                  // value={0}
                                  // onChange={val => updateItemQuantity(item.description, val)}
                                  value={selectedQuantities['Nachos medium'] || 0} onChange={(val) => handleQuantityChange('Nachos medium', val)}
                                />
                              </div>
                            </div>
                          </SubSection2>
                          <TotalSubSectionMobileViewColdDrink
                          // style={{
                          //   display: 'flex',
                          //   flexDirection: 'column',
                          //   alignItems: 'end',
                          //   lineHeight: '20px',
                          //   fontSize: '12px',
                          //   fontWeight: '500',
                          //   color: '#FFFFFFCC'
                          // }}
                          >
                            {Object.entries(selectedQuantities).map(([flavor, quantity]) => (
                              quantity > 0 &&
                              <span className='sub-section1' key={flavor}>{flavor}: {quantity} X £{nachosPrice.toFixed(2)}</span>
                            ))}
                          </TotalSubSectionMobileViewColdDrink>
                        </div>
                        {Object?.keys(selectedQuantities)?.length > 0 && <div style={{
                          textAlign: 'right'
                        }}>
                          <strong>£ {totalNachosPrice.toFixed(2)}</strong>
                        </div>}
                        <RowButton>
                          <Button isPrimary
                          //  onClick={next}
                          >
                            Add
                          </Button>
                        </RowButton>
                      </div>
                    </div>
                  </Selection>
                }
              </>
            )
        }
      </Container >
    </SectionEntities >
  )
}

export default ConcessionModal;



