export const REWARDS_REQUEST = "bonuscard/REWARDS_REQUEST";
export const REWARDS_SUCCESS = "bonuscard/REWARDS_SUCCESS";

export const BALANCE_REQUEST = "bonuscard/BALANCE_REQUEST";
export const BALANCE_SUCCESS = "bonuscard/BALANCE_SUCCESS";

export const CONCESSIONS_REQUEST = "bonuscard/CONCESSIONS_REQUEST";
export const CONCESSIONS_SUCCESS = "bonuscard/CONCESSIONS_SUCCESS";

export const ORDER_CONCESSION_REQUEST = "bonuscard/ORDER_CONCESSION_REQUEST";
export const ORDER_CONCESSION_SUCCESS = "bonuscard/ORDER_CONCESSION_SUCCESS";

export const BONUS_CARD_INFO_REQUEST = "bonuscard/BONUS_CARD_INFO_REQUEST";
export const BONUS_CARD_INFO_SUCCESS = "bonuscard/BONUS_CARD_INFO_SUCCESS";

export const ACTIVATE_XTRA_CARD_REQUEST = "bonuscard/ACTIVATE_XTRA_CARD_REQUEST";
export const ACTIVATE_XTRA_CARD_SUCCESS = "bonuscard/ACTIVATE_XTRA_CARD_SUCCESS";

export const LOGGED_USER_REQUEST = "bonuscard/LOGGED_USER_REQUEST";
export const LOGGED_USER_SUCCESS = "bonuscard/LOGGED_USER_SUCCESS";
