import styled from 'styled-components';

const offset = `5px`;

export const Gallery = styled.div.attrs({
  className: 'b-entity-gallery'
})`
  .carousel {
    margin: ${({ theme }) => `${theme.indent.m} -${offset} 0 -${offset}`};
  }

  .buttons-row {
    left: ${offset};
    right: ${offset};
  }

  .b-image-with-loader {
    cursor: pointer;
  }
`;

export const CarouselItem = styled.div`
  margin: 0 ${offset};

  .b-image-with-loader__inner {
    padding-top: 56.3%;
  }
`;
