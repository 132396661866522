import Cinemas from './modules/Cinemas';
import Concessions from './modules/Concessions';
import Events from './modules/Events';
import Information from './modules/Information';
import Movies from './modules/Movies';
import Auth from './modules/Auth';
import User from './modules/User';
import Sessions from './modules/Sessions';
import SessionsTicket from './modules/SessionsTicket';
import Orders from './modules/Orders';
import Payment from './modules/Payment';
import Bookings from './modules/Bookings';
import Barcode from './modules/Barcode';
import PDF from './modules/PDF';
import SeatPlan from './modules/SeatPlan';
import Watchlist from './modules/Watchlist';
import Giftcards from './modules/Giftcards';
import Bonuscard from './modules/Bonuscard';
import Technologies from './modules/Technologies';
import Advertising from './modules/Advertising';
import CinemaRentInquiry from './modules/CinemaRentInquiry';
import CinemaRent from './modules/CinemaRent';
import Portrait from './modules/Portrait';
import Survey from './modules/Survey';
import Competitions from './modules/Competitions';
import Newsletter from './modules/Newsletter';
import FamilyFilmClub from './modules/FamilyFilmClub';
import BonusCardInfo from './modules/BonusCardInfo';

class API {
  constructor(config, storage) {
    const { api, cmsApi, headerTypes } = config;

    const cmsOptions = { ...cmsApi, storage, headerTypes };

    const options = { ...api, storage, headerTypes };

    this.modules = {
      cinemas: new Cinemas(options),
      concessions: new Concessions(options),
      events: new Events(options),
      information: new Information(options),
      movies: new Movies(options),
      auth: new Auth(options),
      user: new User(options),
      sessions: new Sessions(options),
      sessionsTickets: new SessionsTicket(options),
      orders: new Orders(options),
      payment: new Payment(options),
      bookings: new Bookings(options),
      barcode: new Barcode(options),
      pdf: new PDF(options),
      seatPlan: new SeatPlan(options),
      watchlist: new Watchlist(options),
      giftcards: new Giftcards(options),
      bonuscard: new Bonuscard(options),
      technologies: new Technologies(cmsOptions),
      advertising: new Advertising(cmsOptions),
      cinemaRentInquiry: new CinemaRentInquiry(options),
      cinemaRent: new CinemaRent(cmsOptions),
      portrait: new Portrait(cmsOptions),
      survey: new Survey(cmsOptions),
      competitions: new Competitions(cmsOptions),
      familyFilmClub: new FamilyFilmClub(cmsOptions),
      newsletter: new Newsletter(options),
      bonusCardInfo: new BonusCardInfo(cmsOptions)
    };
  }

  getModuleByName(name) {
    if (Object.keys(this.modules).indexOf(name) < 0) {
      throw new Error("API class doesn't contain module with specified name");
    }

    return this.modules[name];
  }
}

export default API;
