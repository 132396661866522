import styled from 'styled-components';
import { textTransform } from 'utils';

export const ListEntity = styled.ul.attrs(({ withSmallPadding }) => ({
  className: `l-entity${withSmallPadding ? ' _small-padding' : ''}`
}))`
  margin: 0 -${({ theme }) => theme.indent.m};
  font-size: 0;

  &._small-padding {
    margin: 0 -${({ theme }) => theme.indent.s};
  }

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.md.width}) {
    margin: 0 -${({ theme }) => theme.indent.s};
  }

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.xs.width}) {
    &,
    &._small-padding {
      margin: 0 -4px;
    }
  }
`;

export const ListEntityItem = styled.li.attrs({
  className: 'l-entity__item'
})`
  vertical-align: top;
  width: 25%;
  padding: 0 ${({ theme }) => theme.indent.m};

  ._small-padding & {
    padding: 0 ${({ theme }) => theme.indent.s};
  }

  &:nth-child(4) ~ li {
    margin-top: 32px;
  }

  .l-entity__item-link {
    width: 100%;

    & .b-image-with-loader__img {
      transition: transform 0.4s ease-out;
    }

    &:hover {
      .b-image-with-loader__img {
        transform: scale(1.05);
        will-change: transform;
      }

      .l-entity__figure-caption {
        color: ${({ theme }) => theme.color.labelColor};
      }      
    }
    .l-entity__figure-caption_startDate {
      color: #A1A1A1;
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.md.width}) {
    padding: 0 ${({ theme }) => theme.indent.s};
  }

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.sm.width}) {
    width: 33.33333%;

    &:nth-child(3) ~ li {
      margin-top: 28px;
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.xs.width}) {
    width: 50%;

    &,
    ._small-padding & {
      padding: 0 4px;
    }

    &:nth-child(2) ~ li {
      margin-top: 28px;
    }
  }
`;

export const ListEntityFigure = styled.figure.attrs({
  className: 'l-entity__figure'
})`
  position: relative;
  backface-visibility: hidden;

  .l-entity__figure-caption {
    margin-top: ${({ theme }) => theme.indent.m};
    font-size: 1.6rem;
    font-weight: 500;
    text-transform: ${textTransform};
    transition: color 0.35s ease-out;
  }
  .l-entity__figure-caption_startDate  {
    margin-top: 8px;
    font-size: 1.6rem;
    font-weight: 500;
    text-transform: none;
    transition: color 0.35s ease-out;
  }

  ._small-padding & .l-entity__figure-caption {
    margin-top: 10px;
    font-size: 1.4rem;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.sm.width}) {
    .l-entity__figure-caption,.l-entity__figure-caption_startDate{
      font-size: 1.3rem;
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.xs.width}) {
    .l-entity__figure-caption.l-entity__figure-caption_startDate {
      margin-top: ${({ theme }) => theme.indent.s};
    }
  }
`;

export const ListEntitySubtitle = styled.li.attrs({
  className: 'l-entity__subtitle'
})`
  margin-top: 3px;
  font-size: 1.4rem;
  color: ${({ theme }) => theme.color.textSecondary};
`;
