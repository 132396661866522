import styled from 'styled-components';
import { Expander as VendorExpander } from 'app/components';
import { textTransform } from 'utils';

export const BonusInfoWrapper = styled.div.attrs({
  className: 'bonus-info'
})`
  width: 100%;
  max-width: 1238px;
  margin: 0 auto;
  padding: 0 16px;

  .bonus-info__title {
    display: inline-block;
    vertical-align: middle;
    font-size: 2.4rem;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: ${textTransform};
    color: ${({ theme }) => theme.color.textPrimary};
    margin: 24px 0 16px 0;
  }

  a {
    color: #DE1619;
    text-decoration: underline;
  }

  a:hover {
    cursor: pointer;
    outline: 0;
    color: #de1619;
  }
`;

export const BonusCardContent = styled.div.attrs({
  className: 'bonus-card-info'
})`
  .bonus-card-info__image {
    height: 320px;
    width: 100%;
  }

  .bonus-card-info__image-mobile {
    height: 204px;
    object-fit: cover;
  }

  .bonus-card-info__content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    .bonus-card-info__container {
      // max-width: 840px;

      ul {
        & > li {
          list-style: circle;
          display: list-item;

          display: flex;
          align-items: flex-start;
          margin-top: 8px;

          // &:first-child {
          //   margin-top: 0;
          // }

          &:before {
            content: '';
            flex-shrink: 0;
            width: 24px;
            height: 24px;
            padding: 5px;
            margin-right: 8px;
            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 78.369 78.369'%3E%3Cpath fill='%23de1619' d='M78.049 19.015L29.458 67.606a1.094 1.094 0 0 1-1.548 0L.32 40.015a1.094 1.094 0 0 1 0-1.547l6.704-6.704a1.095 1.095 0 0 1 1.548 0l20.113 20.112 41.113-41.113a1.095 1.095 0 0 1 1.548 0l6.703 6.704a1.094 1.094 0 0 1 0 1.548z'/%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-origin: content-box;
          }
        }
      }

      .bonus-card-info__title-wrapper-container{
        width:70%;
        margin:auto;
      }
      
      .bonus-card-info__title-wrapper {
        margin-top: 24px;
        text-align: center;

        h2 {
          font-weight: 400;
          font-size: 24px;
          line-height: 34px;
          letter-spacing: 1.5px;
          text-transform: ${textTransform};
        }

        .bonus-card-info__advantages-container {
          display: flex;
          justify-content: center;

          .bonus-card-info__advantages-wrapper {
            display: grid;
            grid-template-columns: repeat(3, 174px);
            grid-column-gap: 84px;
            grid-row-gap: 20px;
            margin-top: 24px;

            .bonus-card-info__advantage-item {
              width: 174px;
              img {
                width: 96px;
                height: 96px;

                margin-bottom: 16px;
              }
            }
          }
        }

        .bonus-card-info__rewards-item-wrapper {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          grid-column-gap: 16px;
          grid-row-gap: 16px;
          margin-top: 40px;

          .bonus-card-info__rewards-item {
            background: #242424;
            box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.104988);
            border-radius: 8px;
            padding: 16px;
            width: 198px;

            img {
              width: 96px;
              height: 96px;
            }

            h3 {
              font-weight: 450;
              font-size: 24px;
              line-height: 24px;
              text-align: center;
              margin-top: 8px;
            }

            .bonus-card-info__rewards-item-description {
              font-weight: 420;
              font-size: 12px;
              line-height: 16px;
              text-align: center;
              text-transform: ${textTransform};
              margin-top: 8px;
            }
          }
        }
        .bonus-card-info__rewards-description-wrapper {
          margin-top: 16px;
          text-align: left;
          color: #888888;
        }
      }

      .bonus-card-info__recharge-wrapper {
        margin-top: 40px;

        h2 {
          font-weight: 400;
          font-size: 20px;
          line-height: 24px;
          letter-spacing: 1px;
        }

        .bonus-card-info__registration-wrapper {
          margin-top: 22px;

          .bonus-card-info__registration-btn {
            width: 228px;
          }
        }

        .bonus-card-info__recharge-description-wrapper {
          margin-top: 18px;
        }

        .bonus-card-info__recharge-description2-wrapper {
          margin-top: 16px;
        }

        .bonus-card-info__recharge-data-wrapper {
          margin-top: 13px;

          .bonus-card-info__recharge-data-item {
            background: #2d2d2d;
            padding: 12px 16px;
            width: 100%;
            float: left;
            // display: flex;
            // justify-content: space-between;
            margin-top: 2px;
            h3.desciption_h3 {
              width: 50%;
              float: left;
              text-align: center;
              @media screen and (max-width: ${({ theme }) => theme.breakPoints.sm.width}) {
                text-align: left;
              }
              p {
                text-align: left;
              }
            }
            h2.desciption_h2 {
              width: 50%;
              float: left;
              a {
                font-size: 18px;
                @media screen and (max-width: ${({ theme }) => theme.breakPoints.sm.width}) {
                  font-size: 16px;
                }
              }
            }

            &:first-child {
              margin-top: 0;
            }

            h3 {
              font-weight: 400;
              font-size: 16px;
              line-height: 23px;
            }
          }
        }
      }

      .bonus-card-info__upgrade-wrapper {
        margin-top: 40px;

        h2 {
          font-weight: 400;
          font-size: 20px;
          line-height: 24px;
          letter-spacing: 1px;
        }

        .bonus-card-info__upgrade-description-wrapper {
          margin-top: 16px;
        }
      }
      .bonus-card-info__terms-wrapper{
        margin-bottom: 50px;
      }
      .bonus-card-info__faq-wrapper {
        margin: 23px 0 50px 0;

        h2 {
          font-weight: 400;
          font-size: 20px;
          line-height: 23px;
          letter-spacing: 1px;
          margin-bottom: 16px;
        }

        .bonus-card-info__faq-item-wrapper {
          .bonus-card-info__faq-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-top: 1px solid #323232;
            padding: 10px 16px;

            h3 {
              font-weight: 390;
              font-size: 18px;
              line-height: 20px;
              margin: 0;
              text-align: start;
            }
          }
          &:last-child {
            .bonus-card-info__faq-item {
              border-bottom: 1px solid #323232;
              // min-width:44vw;
            }
          }
        }
      }
    }
    @media screen and (max-width: ${({ theme }) => theme.breakPoints.md.width}) {
      .bonus-card-info__container {
      .bonus-card-info__title-wrapper-container{
        width:100%;
        margin:auto;
      }
        .bonus-card-info__title-wrapper {
          .bonus-card-info__advantages-container {
            justify-content: normal;

            .bonus-card-info__advantages-wrapper {
              grid-template-columns: auto;

              .bonus-card-info__advantage-item {
                display: flex;
                align-items: center;
                width: 100%;

                img {
                  width: 56px;
                  height: 56px;

                  margin: 0 16px 0 0;
                }

                .bonus-card-info__advantage-item-description {
                  text-align: left;
                }
              }
            }
          }
          .bonus-card-info__rewards-item-wrapper {
            grid-template-columns: auto;

            .bonus-card-info__rewards-item {
              width: 100%;
              display: flex;
              justify-content: space-between;
              align-items: center;

              img {
                width: 48px;
                height: 48px;
              }

              h3 {
                font-weight: 450;
                font-size: 14px;
                line-height: 14px;
                text-align: right;
                margin: 0 0 0 10px;
              }

              .bonus-card-info__rewards-item-description {
                font-weight: 420;
                font-size: 12px;
                line-height: 16px;
                letter-spacing: 1px;
                text-transform: ${textTransform};
                width: 100%;
                text-align: left;
                margin: 0 0 0 10px;
              }
            }
          }
        }
      }
    }
    
  }
`;

export const Expander = styled(VendorExpander)`
  background: #2d2d2d;
  padding: 0 16px;

  .bonus-card-info__faq-item-expand-content {
    margin: 16px 0;
    text-align:start;
    font-size:16px;
  }
`;
