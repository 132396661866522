import {
  CONCESSIONS_SUCCESS_CATEGORY,
  CONCESSIONS_SUCCESS_ITEM,
  CONCESSIONS_SUCCESS_COMBO,
  CONCESSIONS_SUCCESS_ON_SERVER,
  CONCESSIONS_DATA_STORE,
  CONCESSIONS_SUCCESS_COMBO_ITEM,
  CONCESSIONS_SUCCESS_COMBO_ON_SERVER,
  PICKUP_NUMBER_SUCCESS
} from './types';

const initialState = {
  concessions: [],
  item: null,
  comboItem: null,
  comboItemData: null,
  concessionItem: null,
  concessionComboItem: null,
  concessionData: {
    normalSession: {},
    comboSession: {}
  },
  pickUpNumber: null,
};

export default function (state = initialState, { type, payload }) {
  switch (type) {

    case CONCESSIONS_SUCCESS_CATEGORY: {
      return {
        ...state,
        concessions: payload?.concessions,
      };
    }
    case CONCESSIONS_SUCCESS_ITEM: {
      return {
        ...state,
        item: payload?.item,
      };
    }
    case CONCESSIONS_SUCCESS_COMBO: {
      return {
        ...state,
        comboItem: payload?.comboItem,
      };
    }
    case CONCESSIONS_SUCCESS_COMBO_ITEM: {
      return {
        ...state,
        comboItemData: payload?.comboItemData,
      };
    }
    case CONCESSIONS_SUCCESS_ON_SERVER: {
      return {
        ...state,
        concessionItem: payload?.concessionItem?.order,
      };
    }
    case CONCESSIONS_SUCCESS_COMBO_ON_SERVER: {
      return {
        ...state,
        concessionComboItem: payload?.concessionComboItem?.order,
      };
    }
    case CONCESSIONS_DATA_STORE: {
      return {
        ...state,
        concessionData: {
          normalSession: payload?.normalSession?.normalSession ? payload?.normalSession?.normalSession : {},
          comboSession: payload?.normalSession?.comboSession ? payload?.normalSession?.comboSession : {},
        },
      };
    }
    case PICKUP_NUMBER_SUCCESS: {
      return {
        ...state,
        pickUpNumber: payload.pickUpNumber
      };
    }
    default: {
      return state;
    }
  }
}
