import { mergeMap, startWith, concatMap, map, catchError } from 'rxjs/operators';
import { from, concat } from 'rxjs';
import { ofType } from 'redux-observable';
import { setLoader, handleErrors, createToast, setError } from './deps';
import { CONCESSIONS_COMBO_ON_SERVER, CONCESSIONS_ON_SERVER, CONCESSIONS_REMOVE, CONCESSIONS_REQUEST_CATEGORY, CONCESSIONS_REQUEST_COMBO, CONCESSIONS_REQUEST_COMBO_ITEM, CONCESSIONS_REQUEST_ITEM, PICKUP_NUMBER_REQUEST } from './types';
import { pickUpNumberSuccess, setConcessionCombo, setConcessionComboItem, setConcessionComboOnServer, setConcessionItem, setConcessionOnServer, successConcessionCategories } from './actions';





export function fetchConcessionCategory($action, $state, { api }) {
  const $apiConcessions = api.getModuleByName('concessions');

  return $action.pipe(
    ofType(CONCESSIONS_REQUEST_CATEGORY),
    mergeMap(action =>
      from($apiConcessions.fetchAllCategories()).pipe(
        concatMap(res => [successConcessionCategories(res), setLoader(false)]),

        ...handleErrors(action),
        catchError(err => [setError(err.code, err.message, err), setLoader(false)]),

        startWith(setLoader(true))
      )
    )
  );
}

export function fetchConcessionItem($action, $state, { api }) {
  const $apiConcessions = api.getModuleByName('concessions');

  return $action.pipe(
    ofType(CONCESSIONS_REQUEST_ITEM),
    mergeMap(action => {
      const { cinemaId, id } = action.payload;

      return from($apiConcessions.fetchConcessionById(cinemaId, id)).pipe(
        concatMap(res => [setConcessionItem(res), setLoader(false)]),

        ...handleErrors(action),
        catchError(err => [setError(err.code, err.message, err), setLoader(false)]),

        startWith(setLoader(true))
      )
    })
  );
}

export function fetchConcessionCombo($action, $state, { api }) {
  const $apiConcessions = api.getModuleByName('concessions');

  return $action.pipe(
    ofType(CONCESSIONS_REQUEST_COMBO),
    mergeMap(action => {
      const { cinemaId, id } = action.payload;

      return from($apiConcessions.fetchComboConcessionById(cinemaId, id)).pipe(
        concatMap(res => [setConcessionCombo(res), setLoader(false)]),

        ...handleErrors(action),
        catchError(err => [setError(err.code, err.message, err), setLoader(false)]),

        startWith(setLoader(true))
      )
    })
  );
}

export function fetchConcessionComboItem($action, $state, { api }) {
  const $apiConcessions = api.getModuleByName('concessions');

  return $action.pipe(
    ofType(CONCESSIONS_REQUEST_COMBO_ITEM),
    mergeMap(action => {
      const { cinemaId, id } = action.payload;
      return from($apiConcessions.fetchComboItemConcessionById(cinemaId, id)).pipe(
        concatMap(res => [setConcessionComboItem(res), setLoader(false)]),

        ...handleErrors(action),
        catchError(err => [setError(err.code, err.message, err), setLoader(false)]),

        startWith(setLoader(true))
      )
    })
  );
}

export function fetchConcessionOnServer($action, $state, { api }) {
  const $apiConcessions = api.getModuleByName('concessions');

  return $action.pipe(
    ofType(CONCESSIONS_ON_SERVER),
    mergeMap(action => {
      const { orderId, data } = action.payload;

      return from($apiConcessions.orderConcessionItem(orderId, data)).pipe(
        concatMap(res => [setConcessionOnServer(res), setLoader(false)]),

        ...handleErrors(action),
        catchError(err => [setError(err.code, err.message, err), setLoader(false)]),

        startWith(setLoader(true))
      )
    })
  );
}

export function fetchConcessionComboOnServer($action, $state, { api }) {
  const $apiConcessions = api.getModuleByName('concessions');

  return $action.pipe(
    ofType(CONCESSIONS_COMBO_ON_SERVER),
    mergeMap(action => {
      const { orderId, data } = action.payload;

      return from($apiConcessions.orderConcessionCombo(orderId, data)).pipe(
        concatMap(res => [setConcessionComboOnServer(res), setLoader(false)]),

        ...handleErrors(action),
        catchError(err => [setError(err.code, err.message, err), setLoader(false)]),

        startWith(setLoader(true))
      )
    })
  );
}


export function removeConcession($action, $state, { api }) {
  const $apiConcessions = api.getModuleByName('concessions');

  return $action.pipe(
    ofType(CONCESSIONS_REMOVE),
    mergeMap(action => {
      const { orderId } = action.payload;

      return from($apiConcessions.removeConcessionItem(orderId)).pipe(
        concatMap(res => [setConcessionOnServer(res), setLoader(false)]),

        ...handleErrors(action),
        catchError(err => [setError(err.code, err.message, err), setLoader(false)]),

        startWith(setLoader(true))
      )
    })
  );
}

export const fetchPickUpNumber = ($action, $state, { api }) => {
  const $api = api.getModuleByName('concessions');

  return $action.pipe(
    ofType(PICKUP_NUMBER_REQUEST),
    mergeMap(action => {
      const { cinemaId, bookingNumber } = action.payload;

      return from($api.fetchPickNumber(cinemaId, bookingNumber)).pipe(
        concatMap(res => [pickUpNumberSuccess(res), setLoader(false)]),

        ...handleErrors(action),

        catchError(err =>
          concat([
            createToast('warning', err.message),
            pickUpNumberSuccess(false),
            setLoader(false)
          ])
        ),

        startWith(setLoader(true))
      );
    })
  );
};
