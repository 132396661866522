/* eslint-disable react-hooks/exhaustive-deps  */
/* eslint-disable array-callback-return  */
/* eslint-disable jsx-a11y/anchor-is-valid  */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink, generatePath, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ImageWithLoader } from 'app/components';
import { useConfig } from 'app/hooks';
import { ListEntitySubtitle } from './styles';

// import eventDetails from 'app/modules/events/EventDetails';

const propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  poster: PropTypes.string,
  to: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string
  }).isRequired,
};

const defaultProps = {
  title: null,
  subTitle: null,
  poster: null
};

const ListEntityInner = ({ to, date, title, subTitle, poster, cinemasLocation, pathnameSlug, startDate }) => {
  const { routes } = useConfig();
  const [pathRout, setPathRoute] = useState('');
  const [windowSize, setWindowSize] = useState();

  const events = localStorage.getItem("events")

  const data = JSON.parse(events)
  const getEventId = data?.find((item) => {
    if (window?.location?.pathname.includes(item?.eventUrlName?.split(" ")?.[0])) {
      return item.id;
    }
  })
  const [startDates, setStartDate] = useState([]);
  const toCheck = to => {
    const checkId = to?.id;
    let pathData = '';
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}`;

    const date1 = new Date(formattedDate);
    const date2 = new Date(to?.startDate);

    const compareDates = (dateA, dateB) => {
      const yearA = dateA.getFullYear();
      const monthA = dateA.getMonth() + 1;
      const dayA = dateA.getDate();

      const yearB = dateB.getFullYear();
      const monthB = dateB.getMonth() + 1;
      const dayB = dateB.getDate();
      if (monthA === monthB) {
        return yearA <= yearB && monthA <= monthB && dayA <= dayB;

      }
      else {
        return yearA <= yearB && monthA <= monthB;
      }
    };

    const result = compareDates(date1, date2);

    if (!isNaN(checkId)) {
      pathData = {
        pathname: generatePath(routes.eventDetails.pattern, {
          // id: to?.title?.replaceAll(' ', '-')
          // id: to?.eventUrlName?.replaceAll(' ', '-')
          id: to?.eventUrlName
        }),
        state: { movieId: to?.eventUrlName }
      };
      window.addEventListener('CookiebotOnAccept', function () {
        if (window?.Cookiebot?.consent?.statistics) {
          localStorage.setItem('eventId', to?.id);
        }
      }, false)
      window.addEventListener('CookiebotOnDecline', function () {
        if (window?.Cookiebot?.consent?.statistics) {
          localStorage.setItem('eventId', to?.id);
        }
      }, false)
      // localStorage.setItem('eventId', to?.id);
    } else {
      if (checkId?.slice(0, 2) === 'HO') {
        const eventData = localStorage?.getItem("eventId") !== "undefined" ? JSON.parse(localStorage?.getItem("eventId")) : pathname.split('/')[2];
        pathData = {
          pathname: generatePath(routes.movieDetails.pattern, {
            id: to?.shortURL ? to?.shortURL : to?.titleCalculated?.replaceAll(' ', '-')
            // id: to?.titleCalculated?.replaceAll(' ', '-')
          }),
          search: `${(eventData || getEventId?.id) ? `?location=all&event=${encodeURIComponent(eventData)}&date=${date}` : `?date=${date}&location=${cinemasLocation}`}`,
          // search: `${localStorage.getItem("eventId") || getEventId?.id ? `?location=all&event=${eventData}&date=${date}` : `?date=${date}&location=${cinemasLocation}`}`,
          state: { movieId: to?.id }
        };
        window.addEventListener('CookiebotOnAccept', function () {
          if (window?.Cookiebot?.consent?.statistics) {
            localStorage.setItem('movieId', to?.id);
          }
        }, false)
        window.addEventListener('CookiebotOnDecline', function () {
          if (window?.Cookiebot?.consent?.statistics) {
            localStorage.setItem('movieId', to?.id);
          }
        }, false)
        // localStorage.setItem('movieId', to?.id);
        if (pathnameSlug.includes('events') === false) {
          // localStorage.setItem('eventId', null);
          window.addEventListener('CookiebotOnAccept', function () {
            if (window?.Cookiebot?.consent?.statistics) {
              localStorage.removeItem('eventId');
            }
          }, false)
          window.addEventListener('CookiebotOnDecline', function () {
            if (window?.Cookiebot?.consent?.statistics) {
              localStorage.removeItem('eventId');
            }
          }, false)
        }
      }
    }

    setPathRoute(pathData);
  };
  const [t] = useTranslation();

  useEffect(() => {
    toCheck(to);
  }, [to]);
  const { pathname } = useLocation();
  useEffect(() => {

    let newDate = new Date(startDate);
    let dateMDY = `${(newDate.getDate() < 10) ? '0' + newDate.getDate() : newDate.getDate()}.${((newDate.getMonth() + 1) < 10) ? '0' + (newDate.getMonth() + 1) : (newDate.getMonth() + 1)}.${newDate.getFullYear()} `;
    // console.log("dateMDY", dateMDY)
    setStartDate(oldDate => [...oldDate, dateMDY]);
  }, [])
  useEffect(() => {
    setWindowSize(window.innerWidth);
  }, [window]);

  window.addEventListener('resize', function () {
    setWindowSize(window.innerWidth);
  }, true);

  useEffect(() => {
    if (windowSize <= 425) {
      window.scrollTo({ top: 100, behavior: "smooth" })
    }
  }, [windowSize]);

  return (
    <NavLink className="l-entity__item-link" to={pathRout}>
      <ImageWithLoader imgSrc={poster} imgTitle={title} />
      <figcaption className="l-entity__figure-caption">
        {title || t('movies.no_title')}
      </figcaption>
      {
        pathname.split('/')[1] === 'film' &&
        <figcaption className="l-entity__figure-caption_startDate" >
          {
            startDates?.map((startDate) => {
              return (
                <>
                  {t('movies.film_start')}: {startDate}
                </>
              )
            }
            )
          }
        </figcaption>
      }
      {subTitle ? <ListEntitySubtitle>{subTitle}</ListEntitySubtitle> : null}
    </NavLink>
  );
};

ListEntityInner.propTypes = propTypes;
ListEntityInner.defaultProps = defaultProps;
export default ListEntityInner;
