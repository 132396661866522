import { connect } from 'react-redux';
import BoxMenu from './BoxMenu.component';
import layout from 'app/store/layout';
import concessions from 'app/store/concessions';

const { setModal } = layout.actions;
const { requestConcessionComboItem, setConcessionData, setConcessionComboItem } = concessions.actions;
const mapStateToProps = () => ({
});
const mapDispatchToProps = {
  fetchComboItem: requestConcessionComboItem,
  setModal,
  setConcessionData,
  setConcessionComboItem
};
export default connect(mapStateToProps,
  mapDispatchToProps)(BoxMenu);