import React, { useEffect, useState } from 'react';
import { useConfig, useModal } from 'app/hooks';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from 'app/components';
import { RefundModalContent } from 'app/modules/account/Tickets/styles';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { BonusInfoWrapper } from 'app/modules/info/BonusCard/styles';
import { BlockTickets, Info, Page } from './styles';
import { useSelector } from 'react-redux';

const PickupModal = ({ pickUpNumber, setPickUpNumber }) => {
  const [isShowValidUser, showValidUserModal, hideValidUserModal] = useModal('attention');
  const [isPickUpReady, showPickupReadyModal, hidePickupReadyModal] = useModal('pickupReady');
  const [showPickupNumber, setShowPickupNumber] = useState(false);
  const pickUpNumberData = useSelector(state => state?.concessions?.pickUpNumber)
  const [t, i18n] = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const bookingNumber = location?.pathname?.split('/')[5];
  const cinemaId = location?.pathname?.split('/')[4];
  const {
    routes,
  } = useConfig();
  const handleHomePage = () => {
    hideValidUserModal();
    hidePickupReadyModal();
    history.push(routes.movies.pattern);
  };

  useEffect(() => {
    setPickUpNumber(null);
  }, [])
  const handlePickupNow = () => {
    hideValidUserModal();
    showPickupReadyModal();
  };
  const handlePickupConfirmation = () => {
    hidePickupReadyModal();
    pickUpNumber(cinemaId, bookingNumber);
    setShowPickupNumber(!showPickupNumber);
  }
  useEffect(() => {
    if (!location?.state?.isPickupReady) {
      showValidUserModal();
    } else {
      showPickupReadyModal();
    }
  }, [location?.state?.isPickupReady])
  return (
    <Page>
      <BonusInfoWrapper>
        <div className="container">
          {showPickupNumber ?
            <BlockTickets>
              <Info>Snacks is Ready for Pickup.</Info>
              <Info>Pickup Number : {pickUpNumberData?.PickupNumber}</Info>
              <div className="b-bonus-card__button">
                <NavLink to={routes.movies.pattern} style={{ width: '100%' }}>
                  <Button isPrimary onClick={handleHomePage} style={{ marginTop: '16px' }}>
                    {t('common.to_home_page')}
                  </Button>
                </NavLink>
              </div>
            </BlockTickets>
            :
            <h1>Should Pickup Snacks be ready?</h1>
          }
        </div>
      </BonusInfoWrapper>
      <Modal
        isSmall
        isOpen={isShowValidUser}
        // title={t('purchase.modals.invalid_user_opera_booking.header')}
        title={`Pickup Snacks`}
        close={() => handleHomePage()}
        style={{ pointerEvents: 'none' }}
        shouldCloseOnOverlayClick={false}
      >
        <RefundModalContent>
          <div className="refund_modal__modal_message">
            <h3>{`After Confirmation Snacks will be starts preparing now.`}</h3>
          </div>
          <div className="seasonPass__modal_btns">
            <Button isDefault onClick={handlePickupNow} style={{ textAlign: 'center', cursor: 'pointer', marginRight: '10px' }}>
              {`Pickup Now`}
            </Button>
            <Button isPrimary onClick={handleHomePage}>
              {`Pickup Later`}
            </Button>
          </div>
        </RefundModalContent>
      </Modal>
      <Modal
        isOpen={isPickUpReady}
        // title={t('purchase.modals.invalid_user_opera_booking.header')}
        title={`Pickup Snacks`}
        close={handleHomePage}
        style={{ pointerEvents: 'none' }}
        shouldCloseOnOverlayClick={false}
      >
        <RefundModalContent>
          <div className="refund_modal__modal_message">
            {/* <h3>{t('purchase.modals.invalid_user_opera_booking.label')}</h3> */}
            {/* <h3>{t('purchase.modals.invalid_user_opera_booking.info')}</h3> */}
            <h3>{`Would you like to pickup now ?`}</h3>
          </div>
          <div className="seasonPass__modal_btns">
            <Button isDefault onClick={handlePickupConfirmation} style={{ textAlign: 'center', cursor: 'pointer', marginRight: '10px' }}>
              {`Pickup Now`}
            </Button>
            <Button isPrimary onClick={handleHomePage}>
              {/* {t('common.login')} */}
              {t('common.cancel')}
            </Button>
          </div>
        </RefundModalContent>
      </Modal>
    </Page>
  )

}

export default PickupModal;