import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useConfig from "app/hooks/useConfig";
import useLogout from "app/hooks/useLogout";
import { onClickBehavior } from "app/utils";
import { Svg } from "app/components";
import { List, Item } from "./styles";

const propTypes = {
  isRounded: PropTypes.bool
};

const defaultProps = {
  isRounded: false
};

const Menu = ({ isRounded }) => {
  const [t] = useTranslation();
  const { routes, showingOptions } = useConfig();
  const logout = useLogout();
  const clickBehavior = isRounded ? onClickBehavior : {};

  return (
    <List isRounded={isRounded} {...clickBehavior}>
      <Item>
        <NavLink
          className="menu__link"
          to={routes.user.pattern}
          activeClassName="_active"
        >
          <Svg id="account" />
          <span>{t("common.my_account")}</span>
        </NavLink>
      </Item>

      <Item>
        <NavLink
          className="menu__link"
          to={routes.tickets.pattern}
          activeClassName="_active"
        >
          <Svg id="tickets" />
          <span>{t("common.my_tickets")}</span>
        </NavLink>
      </Item>

      {/* <Item>
        <NavLink
          className="menu__link"
          // to={routes.xXtraCard.patterns}
          to={routes.xXtraCard.pattern}
          activeClassName="_active"
        >
          <Svg id="bonus-card-2" />
          <span>{t("common.xXtra_card")}</span>
        </NavLink>
      </Item> */}

      {showingOptions?.myBonusCard && (
        <Item>
          <NavLink
            className="menu__link"
            to={routes.bonusCard.pattern}
            activeClassName="_active"
            exact
          >
            <Svg id="bonus-card-2" />
            <span>{t("common.xXtra_card")}</span>
            {/* <span>{t('common.my_bonus_card')}</span> */}
          </NavLink>
        </Item>
      )}

      {showingOptions?.myGiftCard && (
        <Item>
          <NavLink
            className="menu__link"
            to={routes.giftCard.pattern}
            activeClassName="_active"
            exact
          >
            <Svg id="gift-card" />
            <span>{t("common.my_giftcards")}</span>
          </NavLink>
        </Item>
      )}

      <Item>
        <NavLink
          className="menu__link"
          to={routes.history.pattern}
          activeClassName="_active"
          exact
        >
          <Svg id="history" />
          <span>{t("common.my_history")}</span>
        </NavLink>
      </Item>

      {showingOptions?.whatchList && (
        <Item>
          <NavLink
            className="menu__link"
            to={routes.whatchList.pattern}
            activeClassName="_active"
            exact
          >
            <Svg id="heart" />
            <span>{t("common.my_watchList")}</span>
          </NavLink>
        </Item>
      )}

      <Item>
        <button type="button" className="menu__link" onClick={logout}>
          <Svg id="logout" />
          <span>{t("common.logout")}</span>
        </button>
      </Item>
    </List>
  );
};

Menu.propTypes = propTypes;
Menu.defaultProps = defaultProps;
export default Menu;
