export default () => ({
  country: "hrv",
  intl: {
    fallbackLng: "hr",
    locales: [
      {
        locale: "hr",
        label: "hrvatski jezik"
      }
    ],
    defaultLocales: [
      {
        locale: "hr",
        label: "hrvatski jezik"
      }
    ]
  },
  api: {
    user: {
      workZipCode: "HRV"
    }
  },

  serverTimeZone: 2,

  footer: {
    copyright: "© Cineplexx HR d.o.o.",
    apps: {
      android: "https://play.google.com/store/apps/details?id=hr.cineplexx.app",
      ios: "https://apps.apple.com/hr/app/cineplexx-hr/id1499522463"
    }
  },

  supportInfo: {
    contactUs: {
      email: "kino@cineplexx.hr"
    }
  },

  form: {
    validation: {
      zip: 5
    }
  },

  wizard: {
    stepTickets: {
      warnings: {
        en: [
          {
            title:
              '<strong>Cineplexx Titan || <span class="_accent">Attention!</span></strong> The 1 + 1 offer is only available at <strong>Cineplexx Titan (Bucharest)</strong> for tickets purchased at the desk, not online!',
            description:
              "Pay at the cinema to receive a bonus ticket for every cinema ticket purchased"
          }
        ],
        de: [
          {
            title:
              '<strong>Cineplexx Titan || <span class="_accent">Atenție!</span></strong> Oferta 1 + 1 este disponibilă doar la <strong>Cineplexx Titan (București)</strong> pentru biletele achiziționate la casele de bilete, nu și online!',
            description:
              "Pentru a beneficia de un bilet bonus pentru fiecare bilet de film cumpărat, plătește la cinema."
          }
        ]
      }
    },
    stepCheckout: {
      messages: {
        en: [""],
        de: [""]
      }
    }
  },
  showingOptions: {
    member: true,
    giftcards: false,
    streaming: false,
    whatchList: true,
    cinemaRent: true,
    advertising: false,
    newsletter: false,
    newsletterfooter: false,
    newsletterpopup: false,
    wishToReceiveSMS: true,
    contactByThirdParty: true,
    information: true,
    followUs: true,
    myBonusCard: true,
    myGiftCard: false,
    refundTickets: false,
    displayTechnologies: true,
    b2bAdvertising: true,
    privacyPolicy: true,
    weeklynewsletter: true,
    familynewsletter: false,
    operanewsletter: false,
    ageratingvisible: true,
    schoolcinema: true,
    childtickets: true,
    company: {
      press: true,
      portrait: true,
      jobs: true
    },
    club: {
      bonusCard: true,
      familyFilmclub: true,
      competitions: false
    },
    giftCards: {
      giftcardShop: false,
      giftcardInfo: false
    }
  }
});
